import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styles from "../DenunciantesModal.module.css";
import toast from "react-hot-toast";
import { useEffect } from "react";
const PersonaStep = ({ nextStep, setRequest, person, isDetail }) => {
  const [documentType, setDocumentType] = useState("CI");
  const [documentNumber, setDocumentNumber] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [profession, setProfession] = useState("");
  const [alias, setAlias] = useState("");
  const [validated, setValidated] = useState(false);
  const [mandatorio, setMandatorio] = useState(false);
  const [type, setType] = useState("PF");

  const types = [
    {
      label: "Ciudadano",
      value: "PF",
    },
    // {
    //     label: "Institución del Estado",
    //     value: "IE"
    // },
    {
      label: "Institución privada",
      value: "IP",
    },
  ];

  const getFormattedDate = () => {
    if (!birthDate) return "";
    const [year, month, day] = birthDate.split("-");
    return `${day}-${month}-${year}`;
  };
  const handleSubmit = () => {
    if (!firstName) {
      toast.error("Por favor, complete el Nombre", {
        position: "bottom-right",
        duration: 1500,
      });
    }
    if (!lastName) {
      toast.error("Por favor, complete el Apellido", {
        position: "bottom-right",
        duration: 1500,
      });
    }
    if (!documentNumber) {
      toast.error("Por favor, complete el numero de documento", {
        position: "bottom-right",
        duration: 1500,
      });
    }
    if (isDetail) {
      nextStep();
    }

    const dateToSend = getFormattedDate();

    const person = {
      documentType: documentType,
      documentNumber: documentNumber,
      firstName: firstName,
      lastName: lastName,
      birthDate: dateToSend,
      gender,
      maritalStatus: maritalStatus,
      profession,
      alias,
    };

    if (documentNumber && documentType && firstName && lastName) {
      setValidated(true);
      setRequest(person);
      nextStep();
    }
  };

  useEffect(() => {
    if (person && isDetail) {
      setDocumentType(person.documentType);
      setDocumentNumber(person.documentNumber);
      setFirstName(person.firstName);
      setLastName(person.lastName);
      setBirthDate(person.birthDate);
      setGender(person.gender);
      setMaritalStatus(
        person?.maritalStatus?.charAt(0).toUpperCase() +
          person?.maritalStatus?.slice(1).toLowerCase()
      );
      setProfession(person.profession);
      setAlias(person.alias);
    }
  }, [person]);

  return (
    <>
      <Form validated={validated}>
        <Row>
          <div className={styles.container}>
            <Row className="mb-4">
              <Form.Label className={styles.groupLabel}>
                Documento de identidad
              </Form.Label>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="documentType"
                    as="select"
                    value={documentType || ""}
                    className={styles.input}
                    disabled={isDetail}
                    onChange={(e) => {
                      setDocumentType(e.target.value);
                      setDocumentNumber("");
                    }}
                    placeholder="Ingrese el tipo de documento"
                  >
                    <option value="">Seleccione tipo de Documento</option>
                    <option value="CI">Cédula de identidad</option>
                    <option value="PAS">Pasaporte</option>
                    <option value="MAT">
                      Matrícula de la Corte Suprema de Justicia
                    </option>
                  </Form.Control>
                  <label htmlFor="documentType" className={styles.label}>
                    Tipo de documento
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="documentNumber"
                    type="text"
                    disabled={isDetail}
                    value={documentNumber || ""}
                    placeholder="Ingrese el número de documento"
                    className={styles.input}
                    onChange={(e) => {
                      setDocumentNumber(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (documentType === "CI" && !/^[0-9]*$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="documentNumber" className={styles.label}>
                    Número de documento
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Label className={styles.groupLabel}>
                Datos personales
              </Form.Label>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    id="firstName"
                    type="text"
                    disabled={isDetail}
                    value={firstName || ""}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    placeholder="Ingrese el nombre de la persona"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="firstName">
                    Nombres
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    id="lastName"
                    value={lastName || ""}
                    disabled={isDetail}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    className={styles.input}
                    type="text"
                    placeholder="Ingrese el apellido de la persona"
                  />
                  <label className={styles.label} htmlFor="lastName">
                    Apellidos
                  </label>
                </Form.Floating>
              </Form.Group>
              {!isDetail && (
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="birthDate"
                      required={false}
                      disabled={isDetail}
                      value={birthDate}
                      onChange={(e) => {
                        setBirthDate(e.target.value);
                      }}
                      className={styles.input}
                      placeholder="Ingrese la fecha de nacimiento"
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <label htmlFor="birthDate" className={styles.label}>
                      Fecha de nacimiento
                    </label>
                  </Form.Floating>
                </Form.Group>
              )}
              {isDetail && (
                 <Form.Group as={Col}>
                 <Form.Floating>
                   <Form.Control
                     required
                     id="birthDate"
                     value={birthDate || ""}
                     disabled={isDetail}
                     className={styles.input}
                     type="text"
                   />
                   <label className={styles.label} htmlFor="birthDate">
                     Fecha de nacimiento
                   </label>
                 </Form.Floating>
               </Form.Group>
              )}
              <Row className="mt-4 mb-4">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="gender"
                      disabled={isDetail}
                      className={styles.input}
                      as="select"
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option value="">Seleccione</option>
                      <option value="F">Femenino</option>
                      <option value="M">Masculino</option>
                    </Form.Control>
                    <label htmlFor="gender" className={styles.label}>
                      Genero
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="maritalStatus"
                      disabled={isDetail}
                      className={styles.input}
                      as="select"
                      value={maritalStatus}
                      onChange={(e) => {
                        setMaritalStatus(e.target.value);
                      }}
                    >
                      <option value="">Seleccione</option>
                      <option value="Casado">Casado/a</option>
                      <option value="Soltero">Soltero/a</option>
                      <option value="Viudo">Viudo/a</option>
                    </Form.Control>
                    <label htmlFor="maritalStatus" className={styles.label}>
                      Estado Civil
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="profession"
                      type="text"
                      value={profession}
                      disabled={isDetail}
                      onChange={(e) => {
                        setProfession(e.target.value);
                      }}
                      placeholder="Ingrese la profesion de la persona"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="profession">
                      Profesión
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row className="mt-2 mb-4">
                <Col md={4}>
                  <Form.Group as={Col}>
                    <Form.Floating>
                      <Form.Control
                        id="alias"
                        type="text"
                        value={alias}
                        disabled={isDetail}
                        onChange={(e) => {
                          setAlias(e.target.value);
                        }}
                        placeholder="Ingrese el alias de la persona"
                        className={styles.input}
                      />
                      <label className={styles.label} htmlFor="alias">
                        Alias
                      </label>
                    </Form.Floating>
                  </Form.Group>
                </Col>
              </Row>
            </Row>

            <Row
              className="mt-4"
              style={{ display: "flex", paddingTop: ".7rem" }}
            >
              <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                <Button bsPrefix={styles.next} onClick={handleSubmit}>
                  SIGUIENTE
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default PersonaStep;
