import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Button,
  Col,
  Row,
  Alert,
  Table,
} from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import toast from "react-hot-toast";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "../DenunciantesModal.module.css";

const AddressStep = ({
  updateAddresses,
  prevStep,
  nextStep,
  addresses,
  setAddresses,
  person,
  isDetail,
}) => {

  const [city, setCity] = useState("");
  const [department, setDepartment] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [principalStreet, setPrincipalStreet] = useState("");
  const [secondaryStreet, setSecondaryStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState(null);
  const [coordinates, setCoordinates] = useState("");
  const [addressType, setAddressType] = useState("PRIMARIA");

  const [showDirection, setShowDirection] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  //const [addresses, setAddresses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);


  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    return axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => {
        setCities(res.status === 200 ? res.data : []);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status == 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const removeAddress = (e, address) => {
    e.preventDefault();
    const updatedAddresses = addresses.filter((current) => current !== address);

    setAddresses(updatedAddresses);

    updateAddresses(updatedAddresses);
  };
  const handleChangeNeighborhood = (event, value, reason, details) => {
    event.preventDefault();
    setNeighborhood(typeof value == "object" ? value : { name: value });
  };

  useEffect(() => {
    if (isDetail === true) {
      setAddresses(person.addresses);
    }
  }, [person]);

  useEffect(() => {
    findDeparments();
    setCity(undefined);
  }, []);
  useEffect(() => {
    if (department != undefined) {
      findCities(department);
      setNeighborhoods([]);
    }
  }, [department]);
  useEffect(() => {
    if (city != undefined) {
      findNeighborhoods(city);
    }
  }, [city]);

  useEffect(() => {}, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const array = () => {
    if (!validateFields()) {
      return;
    }
    let depName = departments.find((dep) => dep.id == department)?.name || "";
    let cityName = cities.find((ct) => ct.id == city)?.name || "";
    let neighborhoodName =
      typeof neighborhood === "object" ? neighborhood.name : "";
    const newAddress = {
      city: cityName,
      department: depName,
      neighborhood: neighborhoodName,
      description: description,
      country: country,
      principal_street: principalStreet,
      secondary_street: secondaryStreet,
      house_number: houseNumber,
      coordinates: coordinates,
      address_type: addressType,
    };
    const newAddresses = [...addresses];
    newAddresses.push(newAddress);
    setAddresses(newAddresses);
    updateAddresses(newAddresses);
    cleanAll();
  };

  const cleanAll = () => {
    setCity(null);
    setDepartment(null);
    setNeighborhood("");
    setDescription("");
    setCountry("");
    setPrincipalStreet("");
    setSecondaryStreet("");
    setHouseNumber("");
    setCoordinates("");
    setAddressType("PRIMARIA");
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const { keycloak } = useKeycloak();

  const [validated, setValidated] = useState(false);

  const validateFields = () => {
    if (!department || !city) {
      toast.error("Debe seleccionar un departamento y una ciudad.");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(isDetail){nextStep()}
    const hasPrimary = addresses.some(
      (address) =>
        address.address_type === "PRIMARIA" ||
        address.address_type.toLowerCase() === "principal"
    );

    if (!hasPrimary) {
      setShowAlert(true);
      return;
    }

    setShowAlert(false);

    setValidated(true);
    nextStep();
  };

  const skip = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <>
      <Form validated={validated}>
        <Row>
          <div className={styles.container}>
            <Form.Label className={styles.groupLabel}>Dirección</Form.Label>
            {showAlert && (
              <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                <Alert.Heading>Error de validación</Alert.Heading>
                <p>Debe haber al menos una dirección de tipo "Primaria".</p>
              </Alert>
            )}

            <Row className="mt-2 mb-4">
              <Form.Group as={Col} controlId="departamentValidation">
                <Form.Label className={styles.label}>Departamento</Form.Label>
                <Form.Select
                  className={styles.input}
                  value={department ?? ""}
                  disabled={isDetail}
                  onChange={(e) => setDepartment(e.target.value)}
                >
                  <option value={undefined}>Seleccione</option>
                  {departments.map((current) => (
                    <option key={current.id} value={current.id}>
                      {current.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="cityValidation">
                <Form.Label className={styles.label}>Ciudad</Form.Label>
                <Form.Select
                  className={styles.input}
                  disabled={isDetail}
                  value={city ?? ""}
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option value={undefined}>Seleccione</option>
                  {cities.map((current) => (
                    <option key={current.id} value={current.id}>
                      {current.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="neighborhoodValidation">
                <Autocomplete
                  options={neighborhoods}
                  disabled={isDetail}
                  getOptionLabel={(option) =>
                    typeof option === "object" ? option.name : option
                  }
                  value={neighborhood || ""}
                  className={styles.input}
                  freeSolo
                  autoSelect
                  onChange={handleChangeNeighborhood}
                  renderInput={(params) => (
                    <TextField
                      className={styles.label}
                      {...params}
                      label="Barrio / Localidad"
                      variant="standard"
                      placeholder="Seleccione"
                    />
                  )}
                />
              </Form.Group>
            </Row>
            <Row>
              <Row className="mt-2 mb-4">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      name="description"
                      value={description}
                      disabled={isDetail}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      placeholder="Ingrese la descripción"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="description">
                      Descripción
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      name="country"
                      value={country}
                      disabled={isDetail}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      placeholder="Ingrese el país"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="country">
                      País
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      name="principal_street"
                      value={principalStreet}
                      disabled={isDetail}
                      onChange={(e) => {
                        setPrincipalStreet(e.target.value);
                      }}
                      placeholder="Ingrese la calle principal"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="principal_street">
                      Calle principal
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row className="mt-2 mb-4">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      name="secondary_street"
                      disabled={isDetail}
                      value={secondaryStreet}
                      onChange={(e) => {
                        setSecondaryStreet(e.target.value);
                      }}
                      placeholder="Ingrese la calle secundaria"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="secondary_street">
                      Calle secundaria
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      name="house_number"
                      value={houseNumber}
                      disabled={isDetail}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          setHouseNumber(inputValue);
                        }
                      }}
                      placeholder="Ingrese el número de casa"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="house_number">
                      Número de casa
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      name="coordinates"
                      value={coordinates}
                      disabled={isDetail}
                      onChange={(e) => {
                        setCoordinates(e.target.value);
                      }}
                      placeholder="Ingrese las coordenadas"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="coordinates">
                      Coordenadas
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row className="mt-2 mb-4">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      name="address_type"
                      className={styles.input}
                      as="select"
                      disabled={isDetail}
                      value={addressType}
                      onChange={(e) => {
                        setAddressType(e.target.value);
                      }}
                      required
                    >
                      <option value="PRIMARIA">Primaria</option>
                      <option value="SECUNDARIA">Secundaria</option>
                    </Form.Control>
                    <label htmlFor="address_type" className={styles.label}>
                      Tipo de dirección
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>

            {addresses?.length > 0 ? (
              <Table className={styles.table} style={{ marginTop: "1rem" }}>
                <thead>
                  <th>Departamento</th>
                  <th>Ciudad</th>
                  <th>Barrio</th>
                  <th>Calle Principal</th>
                  <th>Calle Secundaria</th>
                  <th>Número de casa</th>
                  {!isDetail && <th>Acciones</th>}
                </thead>
                <tbody>
                  {addresses.map((address) => (
                    <tr>
                      <td>{address.department}</td>
                      <td>{address.city}</td>
                      <td>{address.neighborhood}</td>
                      <td>{address.principal_street}</td>
                      <td>{address.secondary_street}</td>
                      <td>{address.house_number}</td>
                      {!isDetail && (
                        <td>
                          <AiOutlineFileSearch
                            disabled={isDetail}
                            onClick={(e) => {
                              e.preventDefault();
                              if (isDetail) {
                                return;
                              }
                              setSelectedAddress(address);
                              setShowDirection(true);
                            }}
                            title="Ver"
                            className={styles.icon}
                          />
                          &nbsp;
                          <FiTrash
                            onClick={(e) => {
                              if (isDetail) {
                                return;
                              }
                              removeAddress(e, address);
                            }}
                            title="Descartar"
                            className={styles.icon}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}

            {isDetail ? (
              <></>
            ) : (
              <Button
                bsPrefix={styles.next}
                onClick={() => {
                  array();
                }}
                className="mt-3"
              >
                GUARDAR DIRECCION
              </Button>
            )}

            <Row
              className="mt-4"
              style={{ display: "flex", paddingTop: ".7rem" }}
            >
              <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                <Button bsPrefix={styles.next} onClick={prevStep}>
                  ANTERIOR
                </Button>
              </Col>

              {addresses?.length > 0 ? (
                <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                  <Button bsPrefix={styles.next} onClick={handleSubmit}>
                    SIGUIENTE
                  </Button>
                </Col>
              ) : (
                <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                  <Button bsPrefix={styles.next} onClick={skip}>
                    OMITIR
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default AddressStep;
