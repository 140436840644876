import React from "react";
import {Table } from "react-bootstrap";
import styles from "./PersonDetailsModal.module.css";

const PersonDetailsModal = ({ person }) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nombre</td>
            <td>{person.first_name}</td>
          </tr>
          <tr>
            <td>Apellido</td>
            <td>{person.last_name}</td>
          </tr>
          <tr>
            <td>Alias</td>
            <td>{person.alias}</td>
          </tr>
          <tr>
            <td>Tipo de documento</td>
            <td>{person.document_type}</td>
          </tr>
          <tr>
            <td>Documento</td>
            <td>{person.document_number}</td>
          </tr>

          <tr>
            <td>Fecha de cumpleaños</td>
            <td>{person.birth_date}</td>
          </tr>
          <tr>
            <td>Sexo</td>
            <td>{person.gender}</td>
          </tr>
          <tr>
            <td>Estado civil</td>
            <td>{person.marital_status}</td>
          </tr>
          <tr>
            <td>Profesion</td>
            <td>{person.profession}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default PersonDetailsModal;
