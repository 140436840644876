import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import styles from "./FuncionarioModal.module.css";

const FuncionarioModal = ({
  show,
  onHide,
  pep,
  setPep,
  currentDefendant,
  setPublicOfficial,
}) => {
    
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="mb-3">
              <Form.Group as={Col} md={3} controlId="pepValidation">
                <Form.Check
                  label="Perfil politico prioritario"
                  type="switch"
                  name="pep"
                  id="pep"
                  style={{
                    accentColor: "#AD0039",
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingBottom: "0px",
                    paddingRight: "0px",
                  }}
                  value={pep}
                  defaultValue={pep}
                  onChange={(e) => setPep(e.target.checked)}
                  className={styles.label}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={3} controlId="publicOfficialValidation">
                <Form.Check
                  label="Funcionario público"
                  type="switch"
                  name="publicOfficial"
                  id="publicOfficial"
                  style={{
                    accentColor: "#AD0039",
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingBottom: "0px",
                    paddingRight: "0px",
                  }}
                  defaultChecked={
                    currentDefendant
                      ? currentDefendant.publicOfficial_boolean
                      : false
                  }
                  onChange={(e) => setPublicOfficial(e.target.checked)}
                  className={styles.label}
                />
              </Form.Group>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FuncionarioModal;
