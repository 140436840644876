import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch, AiOutlineClear } from 'react-icons/ai';
import {Form, Button, Col, Row, Table, InputGroup, DropdownButton, Dropdown, Badge } from "react-bootstrap";
import { RiTableFill, RiArticleFill, RiSortAsc, RiSortDesc, RiCloseFill } from "react-icons/ri";
import { FaFileCirclePlus } from "react-icons/fa6";
import styles from "./CGRConsulta.module.css";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import ReportCard from "../../components/ContraloriaForm/ReportCard";

const CGRConsulta = () => {
    const [ reports, setReports ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState(1);
    const [ pageSize, setPageSize ] = useState(2);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ totalElements, setTotalElements ] = useState(0);

    // Filtros
    const [ perjuryAmountMin, setPerjuryAmountMin ] = useState("");
    const [ perjuryAmountMax, setPerjuryAmountMax ] = useState("");
    const [ cgrDateAfter, setCgrDateAfter ] = useState("");
    const [ cgrDateBefore, setCgrDateBefore ] = useState("");
    const [ fgeDateAfter, setFgeDateAfter ] = useState("");
    const [ fgeDateBefore, setFgeDateBefore ] = useState("");
    const [ udeaDateAfter, setUdeaDateAfter ] = useState("");
    const [ udeaDateBefore, setUdeaDateBefore ] = useState("");
    const [ receivedAfter, setReceivedAfter ] = useState("");
    const [ receivedBefore, setReceivedBefore ] = useState("");
    const [ resolutionCgrNumber, setResolutionCgrNumber ] = useState("");
    const [ resolutionFgeNumber, setResolutionFgeNumber ] = useState("");
    const [ resolutionUdeaNumber, setResolutionUdeaNumber ] = useState("");
    const [ crimeIds, setCrimeIds ] = useState("");

    // Ordenamiento
    const [ sorting, setSorting ] = useState([]);

    const [ view, setView ] = useState("C");

    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const prepareHeaders = () => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${keycloak.token}`,
                "channel": "1"
            }
        };
    }

    const resetFilters = (e) => {
        e.preventDefault();
        setPerjuryAmountMin("");
        setPerjuryAmountMax("");
        setCgrDateAfter("");
        setCgrDateBefore("");
        setFgeDateAfter("");
        setFgeDateBefore("");
        setUdeaDateAfter("");
        setUdeaDateBefore("");
        setReceivedAfter("");
        setReceivedBefore("");
        setResolutionCgrNumber("");
        setResolutionFgeNumber("");
        setResolutionUdeaNumber("");
        setCrimeIds("");
        setSorting([]);
        setPage(1);
    }

    const buildParams = () => {
        let query = `?page=${page}&page_size=${pageSize}`;
        if(perjuryAmountMin) { query += `&perjury_amount_min=${perjuryAmountMin}`; }
        if(perjuryAmountMax) { query += `&perjury_amount_max=${perjuryAmountMax}`; }
        if(cgrDateAfter) { query += `&cgr_date_after=${cgrDateAfter}`; }
        if(cgrDateBefore) { query += `&cgr_date_before=${cgrDateBefore}`; }
        if(fgeDateAfter) { query += `&fge_date_after=${fgeDateAfter}`; }
        if(fgeDateBefore) { query += `&fge_date_before=${fgeDateBefore}`; }
        if(udeaDateAfter) { query += `&udea_date_after=${udeaDateAfter}`; }
        if(udeaDateBefore) { query += `&udea_date_before=${udeaDateBefore}`; }
        if(receivedAfter) { query += `&received_after=${receivedAfter}`; }
        if(receivedBefore) { query += `&received_before=${receivedBefore}`; }
        if(resolutionCgrNumber) { query += `&resolution_cgr_number=${resolutionCgrNumber}`; }
        if(resolutionFgeNumber) { query += `&resolution_fge_number=${resolutionFgeNumber}`; }
        if(resolutionUdeaNumber) { query += `&resolution_udea_number=${resolutionUdeaNumber}`; }

        if(crimeIds) {
            const idsArray = crimeIds.split(",").map(id => id.trim());
            idsArray.forEach(id => { query += `&crime_ids=${id}`; });
        }

        sorting.forEach(s => {
            query += `&sort_by=${s.key}:${s.direction}`;
        });

        return query;
    }

    const findReports = () => {
        setLoading(true);
        const URL = process.env.REACT_APP_API_URL + "/contraloria/reports" + buildParams();
        axios.get(URL, prepareHeaders())
        .then(res => {
            setReports(res.data.content || []);
            setTotalPages(res.data.totalPages || 1);
            setTotalElements(res.data.totalElements || 0);
            setLoading(false);
        })
        .catch(err => {
            setReports([]);
            setLoading(false);
        });
    }

    const sortingBy = (field, label) => {
        const exists = sorting.find((s) => s.key === field);
        if(exists){
            setSorting(sorting.filter((s) => s.key !== field));
        } else {
            setSorting([...sorting, { key: field, label, direction: "ASC" }]);
        }
    }

    const changeDirection = (sortItem) => {
        let elements = sorting.map((s) => {
            if(s.key === sortItem.key){
                return {...s, direction: s.direction === "ASC" ? "DESC" : "ASC"};
            }
            return s;
        });
        setSorting(elements);
    }

    useEffect(() => {
        findReports();
    }, [page, pageSize, sorting]);

    return (
        <>
            <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
                <Col md={3}>
                    <div className={styles.sideBar}>
                        <Form>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="number"
                                            placeholder="Monto mínimo"
                                            value={perjuryAmountMin}
                                            className={styles.inputFloating}
                                            onChange={(e) => setPerjuryAmountMin(e.target.value)}
                                        />
                                        <label className={styles.label}>Monto mínimo</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="number"
                                            placeholder="Monto máximo"
                                            value={perjuryAmountMax}
                                            className={styles.inputFloating}
                                            onChange={(e) => setPerjuryAmountMax(e.target.value)}
                                        />
                                        <label className={styles.label}>Monto máximo</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Label style={{ fontWeight: "bold" }}>Fecha CGR</Form.Label>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="date"
                                            value={cgrDateAfter}
                                            className={styles.input}
                                            onChange={(e) => setCgrDateAfter(e.target.value)}
                                        />
                                        <label className={styles.label}>Desde</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="date"
                                            value={cgrDateBefore}
                                            className={styles.input}
                                            onChange={(e) => setCgrDateBefore(e.target.value)}
                                        />
                                        <label className={styles.label}>Hasta</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Label style={{ fontWeight: "bold" }}>Fecha FGE</Form.Label>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="date"
                                            value={fgeDateAfter}
                                            className={styles.input}
                                            onChange={(e) => setFgeDateAfter(e.target.value)}
                                        />
                                        <label className={styles.label}>Desde</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="date"
                                            value={fgeDateBefore}
                                            className={styles.input}
                                            onChange={(e) => setFgeDateBefore(e.target.value)}
                                        />
                                        <label className={styles.label}>Hasta</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Label style={{ fontWeight: "bold" }}>Fecha UDEA</Form.Label>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="date"
                                            value={udeaDateAfter}
                                            className={styles.input}
                                            onChange={(e) => setUdeaDateAfter(e.target.value)}
                                        />
                                        <label className={styles.label}>Desde</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="date"
                                            value={udeaDateBefore}
                                            className={styles.input}
                                            onChange={(e) => setUdeaDateBefore(e.target.value)}
                                        />
                                        <label className={styles.label}>Hasta</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Label style={{ fontWeight: "bold" }}>Fecha de recepción</Form.Label>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="datetime-local"
                                            value={receivedAfter}
                                            className={styles.input}
                                            onChange={(e) => setReceivedAfter(e.target.value)}
                                        />
                                        <label className={styles.label}>Desde</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="datetime-local"
                                            value={receivedBefore}
                                            className={styles.input}
                                            onChange={(e) => setReceivedBefore(e.target.value)}
                                        />
                                        <label className={styles.label}>Hasta</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Resolución CGR N°"
                                            value={resolutionCgrNumber}
                                            className={styles.inputFloating}
                                            onChange={(e) => setResolutionCgrNumber(e.target.value)}
                                        />
                                        <label className={styles.label}>Resolución CGR N°</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Resolución FGE N°"
                                            value={resolutionFgeNumber}
                                            className={styles.inputFloating}
                                            onChange={(e) => setResolutionFgeNumber(e.target.value)}
                                        />
                                        <label className={styles.label}>Resolución FGE N°</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Resolución UDEA N°"
                                            value={resolutionUdeaNumber}
                                            className={styles.inputFloating}
                                            onChange={(e) => setResolutionUdeaNumber(e.target.value)}
                                        />
                                        <label className={styles.label}>Resolución UDEA N°</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Delitos (IDs, separados por coma)"
                                            value={crimeIds}
                                            className={styles.inputFloating}
                                            onChange={(e) => setCrimeIds(e.target.value)}
                                        />
                                        <label className={styles.label}>Delitos (IDs)</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: "1rem" }}>
                                <Col style={{ textAlign: "right", marginTop: "auto", display: "flex" }}>                                    
                                    <DropdownButton bsPrefix={styles.secondary} title="Ordenar" style={{ marginLeft: "auto" }}>
                                        <Dropdown.Item onClick={(e) => sortingBy("cgr_date", "Fecha CGR")} className={styles.item}>Fecha CGR</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("fge_date", "Fecha FGE")} className={styles.item}>Fecha FGE</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("udea_date", "Fecha UDEA")} className={styles.item}>Fecha UDEA</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("received_at", "Fecha recepción")} className={styles.item}>Fecha recepción</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("perjury_amount", "Monto")} className={styles.item}>Monto</Dropdown.Item>
                                    </DropdownButton>&nbsp;&nbsp;
                                    <Button onClick={resetFilters}  bsPrefix={styles.primary}>Limpiar <AiOutlineClear /></Button>&nbsp;&nbsp;
                                    <Button onClick={() => { setPage(1); findReports(); }}  bsPrefix={styles.primary}>Buscar <AiOutlineSearch /></Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col md={9}>
                    <div className={styles.container}>
                        <h2>Reportes de Contraloría</h2>
                        <Row>
                            <Col>
                                <Button onClick={(e) => {e.preventDefault(); navigate("/cgr/registrar"); }} bsPrefix={styles.newPrimary} style={{ marginLeft: "auto" }}>&nbsp;&nbsp;Nuevo reporte &nbsp;&nbsp;<FaFileCirclePlus />&nbsp;&nbsp;</Button>
                            </Col>                                
                        </Row>
                        <Row style={{ flexDirection: "row-reverse" }}>
                            <Col md="auto" style={{ textAlign: "right" }}>
                                <InputGroup style={{ flexWrap: "wrap", alignItems: "stretch", justifyContent: "flex-end" }}>
                                    <RiTableFill style={{ marginTop: "auto" }} className={ view === "T" ? styles.iconActive : styles.iconInactive } onClick={(e) => { e.preventDefault(); setView("T"); setPageSize(9); setPage(1); }} />
                                    &nbsp;
                                    <RiArticleFill style={{ marginTop: "auto" }} className={ view === "C" ? styles.iconActive : styles.iconInactive } onClick={(e) => { e.preventDefault(); setView("C"); setPageSize(2); setPage(1); }}/>
                                </InputGroup>
                            </Col>
                            <Col md="auto" style={{ textAlign: "right" }}>
                                {
                                    sorting.map((sort) => (
                                        <Badge className={styles.sortItem} key={sort.key}>
                                            {sort.label}
                                            &nbsp;
                                            {
                                                sort.direction === "ASC"
                                                ? <RiSortAsc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/> 
                                                : <RiSortDesc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/>
                                            }
                                            &nbsp; 
                                            <RiCloseFill style={{ cursor: "pointer" }} onClick={(e) => { sortingBy(sort.key, sort.label) }}/>
                                        </Badge>
                                    ))
                                }
                            </Col>
                        </Row>                         
                        <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem"}}>
                            <Col md={6}>
                                <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={(p) => setPage(p)} totalPages={totalPages}/>
                            </Col>
                            <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>
                                <span>{totalElements} {totalElements > 1 ? "reportes" : "reporte"}</span>
                            </Col>
                        </Row>
                        {
                            loading ? <span className="mt-3 mb-2">Buscando...</span>
                            : 
                            (!loading && (!reports || reports.length === 0)) ? <span className="mt-3 mb-2">No hay resultados</span>
                            :
                            (
                                <>
                                    { 
                                        view === "T" ? 
                                        (
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Fecha CGR</th>
                                                        <th>Fecha FGE</th>
                                                        <th>Fecha UDEA</th>
                                                        <th>Fecha recepción</th>
                                                        <th>Resol. CGR</th>
                                                        <th>Resol. FGE</th>
                                                        <th>Resol. UDEA</th>
                                                        <th>Monto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        reports.map((report) => (
                                                            <tr key={report.report_contraloria_id}>
                                                                <td>{report.report_contraloria_id}</td>
                                                                <td>{report.resolution_cgr?.date || "-"}</td>
                                                                <td>{report.resolution_fge?.date || "-"}</td>
                                                                <td>{report.resolution_udea?.date || "-"}</td>
                                                                <td>{report.received_at || "-"}</td>
                                                                <td>{report.resolution_cgr?.number || "-"}</td>
                                                                <td>{report.resolution_fge?.number || "-"}</td>
                                                                <td>{report.resolution_udea?.number || "-"}</td>
                                                                <td>{report.perjury_amount || "-"}</td>
                                                            </tr> 
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        ) 
                                        :
                                        (
                                            reports.map((report) => (
                                                // <div className={styles.cardView} key={report.report_contraloria_id}>
                                                //     <h5>Reporte ID: {report.report_contraloria_id}</h5>
                                                //     <p><strong>Fecha CGR:</strong> {report.resolution_cgr?.date || "-"}</p>
                                                //     <p><strong>Fecha FGE:</strong> {report.resolution_fge?.date || "-"}</p>
                                                //     <p><strong>Fecha UDEA:</strong> {report.resolution_udea?.date || "-"}</p>
                                                //     <p><strong>Fecha recepción:</strong> {report.received_at || "-"}</p>
                                                //     <p><strong>Resol. CGR:</strong> {report.resolution_cgr?.number || "-"}</p>
                                                //     <p><strong>Resol. FGE:</strong> {report.resolution_fge?.number || "-"}</p>
                                                //     <p><strong>Resol. UDEA:</strong> {report.resolution_udea?.number || "-"}</p>
                                                //     <p><strong>Monto:</strong> {report.perjury_amount || "-"}</p>
                                                // </div>
                                                <ReportCard 
                                                ReportId={report.report_contraloria_id|| "-"}
                                                cgrDate={report.resolution_cgr?.date || "-"}
                                                fgeDate={report.resolution_fge?.date || "-"}
                                                udeaDate={report.resolution_udea?.date || "-"}
                                                receivedAt={report.received_at || "-"}
                                                resolutionCgrNumber={report.resolution_cgr?.number || "-"}
                                                resolutionFgeNumber={report.resolution_fge?.number || "-"}
                                                resolutionUdeaNumber={report.resolution_udea?.number || "-"}
                                                perjuryAmount={report.perjury_amount || "-"}
                                                institution={report.institution}
                                                
                                                />
                                            ))
                                        ) 
                                    }
                                </>
                            )
                        }                                       
                    </div>                    
                </Col>
            </Row>
        </>
    );
}

export default CGRConsulta;
