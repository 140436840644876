import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row, Table } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import styles from "../DenunciantesModal.module.css";
const Contactos = ({
  updateContacts,
  prevStep,
  nextStep,
  contacts,
  setContacts,
  isDetail,
  person,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  ///esto es nuevo//////

  const { keycloak } = useKeycloak();

  const [contactNumber, setContactNumber] = useState(null);
  const [contactType, setContactType] = useState("PRIMARIO");
  const [active, setActive] = useState(true);
  const [verified, setVerified] = useState(true);

  //const [contacts, setContacts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [showContact, setShowContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {}, []);

  const removeContact = (e, contact) => {
    e.preventDefault();
    const updatedContacts = contacts.filter((current) => current !== contact);

    setContacts(updatedContacts);

    updateContacts(updatedContacts);
  };

  const array = () => {
    if (!validateFields()) {
      return;
    }
    const newContact = {
      contact_number: contactNumber,
      contact_type: contactType,
      active: active,
      verified: verified,
    };
    const newContacts = [...contacts];
    newContacts.push(newContact);
    setContacts(newContacts);
    updateContacts(newContacts);
    cleanAll();
  };

  const cleanAll = () => {
    setContactNumber("");
    setContactType("");
    setActive("");
    setVerified("");
  };

  const validateFields = () => {
    if (!contactNumber || !contactType) {
      toast.error("Debe cargar un numero de contacto y seleccionar el tipo.");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (contacts.length === 0) {
      console.log("No se han agregado contactos");
      return;
    }

    nextStep();
  };

  const skip = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <>
      <Form>
        <Row>
          <div className={styles.container}>
            <Form.Label className={styles.groupLabel}>Contacto</Form.Label>

            <Row className="mt-4 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    disabled={isDetail}
                    type="text"
                    name="contact_number"
                    value={contactNumber}
                    onChange={(e) => {
                      setContactNumber(e.target.value);
                    }}
                    placeholder="Ingrese el número de contacto"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="contact_number">
                    Número de contacto
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    name="contact_type"
                    disabled={isDetail}
                    className={styles.input}
                    as="select"
                    value={contactType}
                    onChange={(e) => {
                      setContactType(e.target.value);
                    }}
                    required
                  >
                    <option value="">Seleccione</option>
                    <option value="PRIMARIO">Primario</option>
                    <option value="SECUNDARIO">Secundario</option>
                  </Form.Control>
                  <label htmlFor="contact_type" className={styles.label}>
                    Tipo de contacto
                  </label>
                </Form.Floating>
              </Form.Group>
              <Row className="mt-4 mb-4">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      name="active"
                      className={styles.input}
                      disabled={isDetail}
                      as="select"
                      value={active}
                      onChange={(e) => {
                        setActive(e.target.value);
                      }}
                      required
                    >
                      <option value="">Seleccione</option>
                      <option value="true">Activo</option>
                      <option value="false">Inactivo</option>
                    </Form.Control>
                    <label htmlFor="active" className={styles.label}>
                      Estado
                    </label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      name="verified"
                      disabled={isDetail}
                      className={styles.input}
                      as="select"
                      value={verified}
                      onChange={(e) => {
                        setVerified(e.target.value);
                      }}
                      required
                    >
                      <option value="">Seleccione</option>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </Form.Control>
                    <label htmlFor="verified" className={styles.label}>
                      Verificado
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
            {contacts && contacts.length > 0 ? (
              <Table className={styles.table} style={{ marginTop: "1rem" }}>
                <thead>
                  <th>Número de contacto</th>
                  <th>Tipo de contacto</th>
                  <th>Estado</th>
                  <th>Verificado</th>
                  {!isDetail && <th>Acciones</th>}
                </thead>
                <tbody>
                  {contacts.map((contact) => (
                    <tr>
                      <td>{contact.contact_number}</td>
                      <td>
                        {contact.contact_type === "PRIMARIO"
                          ? "Primario"
                          : "Secundario"}
                      </td>
                      <td>{contact.active ? "Activo" : "Inactivo"}</td>
                      <td>{contact.verified ? "Si" : "No"}</td>
                      {!isDetail && (
                        <td>
                          <AiOutlineFileSearch
                            onClick={(e) => {
                              e.preventDefault();
                              if (isDetail) {
                                return;
                              }
                              setSelectedContact(contact);
                              setShowContact(true);
                            }}
                            title="Ver"
                            className={styles.icon}
                          />
                          &nbsp;
                          <FiTrash
                            onClick={(e) => {
                              if (isDetail) {
                                return;
                              }
                              removeContact(e, contact);
                            }}
                            title="Descartar"
                            className={styles.icon}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
            {isDetail ? (
              <></>
            ) : (
              <Button
                bsPrefix={styles.next}
                onClick={() => {
                  array();
                }}
                className="mt-3"
              >
                GUARDAR CONTACTO
              </Button>
            )}

            <Row
              className="mt-4"
              style={{ display: "flex", paddingTop: ".7rem" }}
            >
              <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                <Button bsPrefix={styles.next} onClick={prevStep}>
                  ANTERIOR
                </Button>
              </Col>

              {contacts.length > 0 ? (
                <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                  <Button bsPrefix={styles.next} onClick={handleSubmit}>
                    SIGUIENTE
                  </Button>
                </Col>
              ) : (
                <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                  <Button bsPrefix={styles.next} onClick={skip}>
                    OMITIR
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default Contactos;
