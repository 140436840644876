import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Table,
  Col,
  Row,
  Modal,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import styles from "./ContraloriaForm.module.scss";
import Person from "./PersonForm";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { FaArchive } from "react-icons/fa";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CrimesModal from "../modals/CrimesModal";
import { IoIosAddCircle } from "react-icons/io";
import { BiSolidInstitution } from "react-icons/bi";
import { GoPersonAdd } from "react-icons/go";
import InstitucionForm from "../DenunciaForm/institucion/InstitucionForm";
import { IoPersonCircleOutline } from "react-icons/io5";
import AddInstitutionModal from "../modals/AddInstitutionModal/AddInstitutionModal";

const ContraloriaForm = ({ next, previous, data }) => {
  const MAX_LONG = 9007199254740991;
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [preCarga, setPreCarga] = useState(false);

  const [denunciantes, setDenunciantes] = useState([]);
  const [funcionariosCGR, setFuncionariosCGR] = useState([]);
  const [newFuncionario, setNewFuncionario] = useState(false);
  const [stateInstitutions, setStateInstitutions] = useState([]);
  const [stateInstitution, setStateInstitution] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [telephone, setTelephone] = useState(undefined);
  const [funcionarioModal, setFuncionarioModal] = useState(false);

  const [current, setCurrent] = useState(1);

  const handleNextDenunciantes = (data) => {
    setDenunciantes(data);
    setCurrent(current + 1);
  };

  const handlePrevDenunciantes = (data) => {
    setDenunciantes(data);
    setCurrent(current - 1);
  };

  const [department, setDepartment] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [neighborhood, setNeighborhood] = useState(undefined);
  const [principalStreet, setPrincipalStreet] = useState(undefined);
  const [secondaryStreet, setSecondaryStreet] = useState(undefined);
  const [houseNumber, setHouseNumber] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [udeaNro, setUdeaNro] = useState(undefined);
  const [udeaDate, setUdeaDate] = useState(undefined);
  const [fgeNro, setfgeNro] = useState(undefined);
  const [fgeDate, setfgeDate] = useState(undefined);
  const [cgrNro, setCgrNro] = useState(undefined);
  const [cgrDate, setCgrDate] = useState(undefined);
  const [direction, setDirection] = useState(undefined);
  const [possibleDate, setPossibleDate] = useState(undefined);
  const [selectedCrimes, setSelectedCrimes] = useState([]);
  const [amount, setAmount] = useState(undefined);
  const [injuredInstitutions, setInjuredInstitutions] = useState([]);
  const [fact, setFact] = useState(undefined);
  const [showCrimeModal, setShowCrimeModal] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);
  const [crimes, setCrimes] = useState([]);

  const patrimonialDamageRule = [
    { lawNumber: "1160/97", article: 187 },
    { lawNumber: "1160/97", article: 192 },
  ];

  const [showModalInstitution, setShowModalInstitution] = useState(false);
  const [isCreateInstitution, setIsCreateInstitution] = useState(false);
  const [selectedInstitutionName, setSelectedInstitutionName] = useState("");
  const [institution, setInstitution] = useState({});
  const [searchInstitutionResult, setSearchInstitutionResult] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isInstitutionDetail, setIsInstitutionDetail] = useState(false);

  const [institutions, setInstitutions] = useState([
    {
      code: "SALUD",
      label: "Salud",
      selected: false,
    },
    {
      code: "EDUCACION",
      label: "Educación",
      selected: false,
    },
    {
      code: "SERVICIOS_PUBLICOS",
      label: "Servicios públicos",
      selected: false,
    },
  ]);

  const { keycloak } = useKeycloak();

  const [formattedAmount, setFormattedAmount] = useState("");

  // Estados para ProsecutorDivisions
  const [showModal, setShowModal] = useState(false);
  const [prosecutorDivisions, setProsecutorDivisions] = useState([]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formatAmount = (value) => {
    const cleanValue = value.replace(/[^\d]/g, "");
    const formatted = parseInt(cleanValue).toLocaleString("es-PY", {
      style: "currency",
      currency: "PYG",
      minimumFractionDigits: 0,
    });
    return formatted;
  };

  const rowHechosPunibles = () => {
    let rows = [
      <Row>
        <Col className={styles.groupLabel} md="4">
          Artículo
        </Col>
        <Col className={styles.groupLabel} md="4">
          Fecha
        </Col>
        {/* <Col className={styles.groupLabel} md="4">
          Conexidad
        </Col> */}
      </Row>,
    ];
    selectedCrimes.map((crime) => {
      rows.push(
        <Row>
          <Col sm={4}>
            <span
              className={styles.lawNumberSpan}
            >{`Ley Nº ${crime.law.number} ${crime.name}`}</span>
            <br></br>
          </Col>

          <Col sm={4}>
            <Form.Floating>
              <Form.Control
                id="floatingPossibleDate"
                type="date"
                required={true}
                value={crime.possibleDate}
                onChange={(e) => handleCrimePossibleDate(e, crime)}
                className={styles.input}
              />
              <Form.Label
                htmlFor="floatingPossibleDate"
                className={styles.label}
              >
                Fecha
              </Form.Label>
            </Form.Floating>
          </Col>
          {/* <Col sm={4}>
            <Form.Floating>
              <Form.Check
                type="checkbox"
                name="crimes"
                value={crime.related}
                id={crime.id}
                style={{ accentColor: "#AD0039" }}
                onChange={(e) => handleCheckboxCrimeRelated(e, crime)}
                className={"custom-check"}
                defaultChecked={crime.related}
              />
            </Form.Floating>
          </Col> */}
        </Row>
      );
    });
    return rows;
  };

  const rowFuncionarios = () => {
    console.log(funcionariosCGR);
    let rows = [
      <Row>
        <Col className={styles.groupLabel} md="4">
          Nombres y Apellidos
        </Col>
        <Col className={styles.groupLabel} md="4">
          Cargo
        </Col>
        <Col className={styles.groupLabel} md="4">
          Contacto
        </Col>
      </Row>,
    ];
    funcionariosCGR.map((funcionario) => {
      rows.push(
        <Row>
          <Col md="4">{funcionario.firstName + funcionario.lastName}</Col>
          <Col md="4">{funcionario.rol}</Col>
          <Col md="4">
            {funcionario.contact && funcionario.contact.length > 0
              ? funcionario.contact[0].contact_value
              : ""}
          </Col>
        </Row>
      );
    });
    return rows;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    const numeric = parseInt(inputValue.replace(/[^\d]/g, ""));
    if (isNaN(numeric)) {
      setFormattedAmount("");
    } else if (numeric <= MAX_LONG) {
      const formatted = formatAmount(inputValue);
      setFormattedAmount(formatted);
      setAmount(isNaN(numeric) ? undefined : inputValue.replace(/[^\d]/g, ""));
    } else {
      alert("El máximo valor para el monto es " + MAX_LONG);
    }
  };

  const handleNext = () => {
    next(build());
  };

  const refreshInstitutions = (updated) => {
    let aux = [];
    for (let current of institutions) {
      for (let currentUpdated of updated) {
        if (current.code == currentUpdated.code) {
          current.selected = true;
          break;
        }
      }
      aux.push(current);
    }
    setInstitutions(aux);
  };

  const resetValues = () => {
    setDenunciantes([]);
    setFuncionariosCGR([]);
    setNewFuncionario(false);
    setStateInstitutions([]);
    setStateInstitution(undefined);
    setEmail(undefined);
    setTelephone(undefined);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.log(prepareHeaders());

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // console.log(build());
    else {
      event.preventDefault();
      setValidated(true);
      axios
        .post(
          process.env.REACT_APP_API_URL + "/contraloria/reports",
          build(),
          prepareHeaders()
        )
        .then((res) => {
          console.log("RESPONSE");

          if (res.status == 201) {
            alert("Reporte creado");
          }
          event.preventDefault();
          event.stopPropagation();
          resetValues();
          setTimeout(() => {
            navigate("/");
            toast.success("Formulario creado exitosamente");
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    previous(build());
  };

  // Nuevo build con prosecutorDivisions
  const build = () => {
    return {
      address: {
        department,
        city,
        neighborhood,
        street: {
          principal: principalStreet,
          secondary: secondaryStreet,
        },
        houseNumber,
        description,
      },
      possibleDate,
      crimes: selectedCrimes.map(({ id, ...crime }) => ({
        ...crime,
        crime_id: id,
      })),
      officials: funcionariosCGR,
      description: description,
      resolution_fge: {
        number: fgeNro,
        date: fgeDate,
      },
      resolution_udea: {
        number: udeaNro,
        date: udeaDate,
      },
      resolution_cgr: {
        number: cgrNro,
        date: cgrDate,
      },
      director: direction,
      institution: {
        institution_id: institution.institution_id,
        name: institution.name,
      },
      reception_at: new Date().toISOString(),
      //Campos faltantes los de abajo
      prosecutor_divisions: prosecutorDivisions,
      fact_description: fact,
      perjury_amount: amount,
    };
  };

  const handleSelectInstitution = (e, institution) => {
    e.preventDefault();
    let aux = [];
    for (let current of institutions) {
      if (current.code === institution.code) {
        current.selected = !current.selected;
      }
      aux.push(current);
    }
    setInjuredInstitutions(aux.filter((current) => current.selected));
    setInstitutions(aux);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        channel: 1,
      },
    };
  };

  const checkPatrimonialDamageRule = () => {
    for (let selected of selectedCrimes) {
      for (let rule of patrimonialDamageRule) {
        if (
          rule.lawNumber == selected.law.number &&
          rule.article == selected.article
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const groupBy = (list, keyGetter, identifierKey) => {
    const map = new Map();
    const formattedMap = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key[identifierKey]);
      const formattedCollection = formattedMap.get(JSON.stringify(key));
      if (!collection) {
        map.set(key[identifierKey], [item]);
        formattedMap.set(JSON.stringify(key), [item]);
      } else {
        collection.push(item);
        formattedCollection.push(item);
      }
    });
    return Object.fromEntries(formattedMap);
  };

  const mapCrimes = (rawCrimes, selected) => {
    const mappedCrimes = groupBy(rawCrimes, (rawCrime) => rawCrime.law, "id");
    console.log(mappedCrimes);
    let crimesFormatted = [];
    for (let key in mappedCrimes) {
      crimesFormatted.push({
        law: JSON.parse(key),
        crimes: mappedCrimes[key].map((ctx) => {
          return { ...ctx, selected: checkSelected(ctx, selected) };
        }),
      });
    }
    console.log(crimesFormatted);
    return crimesFormatted;
  };

  const handleCheckboxCrimeRelated = (e, crimeRelated) => {
    const selectedAux = [...selectedCrimes];
    for (let i = 0; i < selectedAux.length; i++) {
      let crimeAux = selectedAux[i];
      if (
        crimeAux.law.id == crimeRelated.law.id &&
        crimeAux.id == crimeRelated.id
      ) {
        selectedAux[i].related = !selectedAux[i].related;
        setSelectedCrimes(selectedAux);
        return;
      }
    }
  };

  const handleCrimePossibleDate = (e, crimeRelated) => {
    const selectedAux = [...selectedCrimes];
    for (let i = 0; i < selectedAux.length; i++) {
      let crimeAux = selectedAux[i];
      if (
        crimeAux.law.id == crimeRelated.law.id &&
        crimeAux.id == crimeRelated.id
      ) {
        selectedAux[i].possibleDate = e.target.value;
        setSelectedCrimes(selectedAux);
        return;
      }
    }
  };

  const checkSelected = (crime, selected) => {
    if (selected && selected.length > 0) {
      for (let current of selected) {
        if (current.id === crime.id) {
          return true;
        }
      }
    }
    return false;
  };

  const findCrimes = (selected) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/crimes", prepareHeaders())
      .then((res) => {
        if (res.status == 200) {
          setCrimes(mapCrimes(res.data, selected));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => setCities(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status == 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const findStateInstitutions = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/state/institutions`,
        prepareHeaders()
      )
      .then((res) => setStateInstitutions(res.status == 200 ? res.data : []))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    findDeparments();
  }, []);
  useEffect(() => findStateInstitutions(), []);
  useEffect(() => {
    if (department) {
      findCities(department.id);
    }
  }, [department]);
  useEffect(() => {
    if (city) {
      findNeighborhoods(city.id);
    }
  }, [city]);

  useEffect(() => {
    if (data) {
      setDepartment(data.address.department);
      setCity(data.address.city);
      setNeighborhood(data.address.neighborhood);
      setPrincipalStreet(data.address.street.principal);
      setSecondaryStreet(data.address.street.secondary);
      setHouseNumber(data.address.houseNumber);
      setDescription(data.address.description);
      setPossibleDate(data.possibleDate);
      setSelectedCrimes(data.crimes);
      setAmount(data.amount);
      setInjuredInstitutions(data.injuredInstitutions);
      setFact(data.fact);

      findCrimes(data.crimes);
      refreshInstitutions(data.injuredInstitutions);
    } else {
      findCrimes([]);
    }
  }, [data]);

  const returnValue = (value) => {
    try {
      return JSON.parse(value).name;
    } catch (e) {
      return value;
    }
  };
  const searchInstitution = () => {
    let uri = "/institutions?page=1&pageSize=10&institution_type=PUBLIC";
    if (selectedInstitutionName.length > 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            uri +
            `&name=${selectedInstitutionName}`,
          prepareHeaders()
        )
        .then((res) => {
          setSearchInstitutionResult(res.data);
        });
      const filteredResults = searchResults.content.filter(
        (person) =>
          person.agent &&
          person.document_number === person.agent.document_number
      );
      setSearchResults({ ...searchResults, content: filteredResults });
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + uri, prepareHeaders())
        .then((res) => {
          setSearchInstitutionResult(res.data);
        });
    }
  };
  useEffect(() => {
    searchInstitution();
  }, [selectedInstitutionName]);
  return (
    <Form validated={validated}>
      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          <h3>Reporte elaborado por la Dirección de Auditoría Forense – CGR</h3>
        </Form.Label>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Datos FGE</Form.Label>

        <Row>
          <Form.Group as={Col} controlId="notaFGEValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingFGENro"
                type="text"
                value={fgeNro}
                className={styles.input}
                placeholder="N°"
                onChange={(e) => setfgeNro(e.target.value)}
              />
              <Form.Label htmlFor="floatingFGENro" className={styles.label}>
                Nota FGE/N°
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col} controlId="dateFgeValidation">
            <Form.Floating>
              <Form.Control
                id="floatingFGEDate"
                type="date"
                required={true}
                value={fgeDate}
                onChange={(e) => setfgeDate(e.target.value)}
                className={styles.input}
              />
              <Form.Label htmlFor="floatingFGEDate" className={styles.label}>
                Fecha FGE
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Datos UDEA</Form.Label>

        <Row>
          <Form.Group as={Col} controlId="notaUdeaValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingUdeaNro"
                type="text"
                value={udeaNro}
                className={styles.input}
                placeholder="N°"
                onChange={(e) => setUdeaNro(e.target.value)}
              />
              <Form.Label htmlFor="floatingUdeaNro" className={styles.label}>
                Nota UDEA/N°
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col} controlId="dateUdeaValidation">
            <Form.Floating>
              <Form.Control
                id="floatingUdeaDate"
                type="date"
                required={true}
                value={udeaDate}
                onChange={(e) => setUdeaDate(e.target.value)}
                className={styles.input}
              />
              <Form.Label htmlFor="floatingUdeaDate" className={styles.label}>
                Fecha UDEA
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>

      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Datos CGR</Form.Label>

        <Row>
          <Form.Group as={Col} controlId="notaCGRValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingCGRNro"
                type="text"
                value={cgrNro}
                className={styles.input}
                placeholder="N°"
                onChange={(e) => setCgrNro(e.target.value)}
              />
              <Form.Label htmlFor="floatingCGRNro" className={styles.label}>
                Nota CGR/N°
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col} controlId="dateCGRValidation">
            <Form.Floating>
              <Form.Control
                id="floatingCGRDate"
                type="date"
                required={true}
                value={cgrDate}
                onChange={(e) => setCgrDate(e.target.value)}
                className={styles.input}
              />
              <Form.Label htmlFor="floatingCGRDate" className={styles.label}>
                Fecha CGR
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>

      {/* createInstitutionModal */}
      <Modal
        size="lg"
        show={isCreateInstitution}
        onHide={() => {
          setIsCreateInstitution(false);
          setInstitution({});
          setIsInstitutionDetail(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Institucion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstitucionForm institution_id={institution.institution_id} isDetail={true}/>
        </Modal.Body>
      </Modal>

      {/* Add InstitutionModal */}
      <AddInstitutionModal
        show={showModalInstitution}
        onHide={() => {
          setShowModalInstitution(false);
        }}
        setIsCreate={setIsCreateInstitution}
        setComplainants={setInstitution}
        complainants={institution}
      />

      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          Institución sometida a la actividad de control de la CGR
          <IoPersonCircleOutline
            style={{
              marginLeft: "1rem",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
            onClick={async (e) => {
              e.preventDefault();
              if (institution) {
                setInstitution(institution);
                setIsInstitutionDetail(true);
                setIsCreateInstitution(true);
              }
            }}
            title="Ver"
            className={styles.icon}
          />
        </Form.Label>
        <Form.Group as={Col} md="6" controlId="nameValidation">
          <div style={{ display: "flex" }}>
            <Form.Control
              required={false}
              id="nameValidation"
              type="text"
              value={
                institution.name ?? "No se ha seleccionado ninguna institucion"
              }
              className={styles.input}
            />

            <Button
              bsPrefix={styles.next}
              onClick={() => {
                setShowModalInstitution(true);
              }}
            >
              Agregar
            </Button>
          </div>
        </Form.Group>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>
          Explicación del caso
        </Form.Label>

        <Row>
          <Form.Group as={Col} controlId="descriptionValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingDescription"
                type="text"
                value={description}
                className={styles.input}
                placeholder="Ingrese la descripción del lugar"
                onChange={(e) => setDescription(e.target.value)}
              />
              <Form.Label
                htmlFor="floatingDescription"
                className={styles.label}
              >
                Descripción
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>
          Director de la Dirección de Auditoría Forense (DAF)
        </Form.Label>

        <Row>
          <Form.Group as={Col} controlId="directorValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingDirector"
                type="text"
                value={direction}
                className={styles.input}
                placeholder="Director"
                onChange={(e) => setDirection(e.target.value)}
              />
              <Form.Label htmlFor="floatingDirector" className={styles.label}>
                Ingrese el nombre y apellidos del Director
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Contacto</Form.Label>

        <Row>
          <Form.Group as={Col} md="6" controlId="contactoEmailValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingEmail"
                type="text"
                value={email}
                className={styles.input}
                placeholder="Dirección de correo electrónico"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Label htmlFor="floatingEmail" className={styles.label}>
                Correo electrónico
              </Form.Label>
            </Form.Floating>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="contactoTelefonoValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingTelefono"
                type="text"
                value={telephone}
                className={styles.input}
                placeholder="Teléfono(s)"
                onChange={(e) => setTelephone(e.target.value)}
              />
              <Form.Label htmlFor="floatingTelefono" className={styles.label}>
                Teléfono(s)
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>

      <Form.Label className={styles.groupLabel}>
        <h4>MONTO</h4>
      </Form.Label>
      <Row className="mb-4">
        <Form.Group as={Col} md={4}>
          <Form.Floating>
            <Form.Control
              id="floatingAmount"
              type="text"
              required={!preCarga}
              placeholder="Ingrese el monto estimado del daño"
              className={styles.input}
              value={formattedAmount}
              onChange={handleChange}
            />
            <Form.Label htmlFor="floatingAmount" className={styles.label}>
              Monto
            </Form.Label>
          </Form.Floating>
        </Form.Group>
      </Row>

      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          Hechos punibles{" "}
          <FaArchive onClick={() => setShowCrimeModal(!showCrimeModal)} />
        </Form.Label>
      </Row>

      <CrimesModal
        showModal={showCrimeModal}
        handleClose={(e) => setShowCrimeModal(false)}
        updateSelectedCrimes={setSelectedCrimes}
      />

      {selectedCrimes.length > 0 ? rowHechosPunibles() : <></>}

      {funcionarioModal ? (
        <div className={styles.modalCenter}>
          <Person
            show={funcionarioModal}
            handleClose={() => setFuncionarioModal(false)}
            next={handleNextDenunciantes}
            previous={handlePrevDenunciantes}
            complainants={denunciantes}
            funcionarios={funcionariosCGR}
            setFuncionarios={setFuncionariosCGR}
            setComplainants={setDenunciantes}
            setNewFuncionario={setNewFuncionario}
          />
        </div>
      ) : (
        <></>
      )}
      <br />

      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          Funcionarios{" "}
          <IoIosAddCircle onClick={() => setFuncionarioModal(true)} />
        </Form.Label>
      </Row>
      {funcionariosCGR.length > 0 ? rowFuncionarios() : <></>}

      <Row>
        <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
          <Button bsPrefix={styles.next} onClick={handleSubmit}>
            GUARDAR
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContraloriaForm;
