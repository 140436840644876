import { Col, Form, Modal, Row } from "react-bootstrap";
import styles from "./MatriculaNumberModal.module.css";

const MatriculaNumberModal = ({
  show,
  onHide,
  selectedMatricula,
  setSelectedMatricula,
}) => {
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Datos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="mb-3">
            <Form.Group as={Col} md={3}>
              <Form.Control
                id="matricula"
                type="text"
                placeholder="matricula"
                className={styles.input}
                required
                value={selectedMatricula}
                onChange={(e) => {
                  setSelectedMatricula(e.target.value);
                }}
              />
            </Form.Group>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MatriculaNumberModal;
