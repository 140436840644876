import React, { useState } from "react";
import Stepper from "../../../components/Stepper";
import Step from "../../../components/Stepper/Step";
import styles from "./Institucion.module.css";
import DatosUbicacion from "./DatosUbicacion/DatosUbicacion";
import DatosGenerales from "./DatosGenerales/DatosGenerales";
import DatosContacto from "./DatosContacto/DatosContacto";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const InstitucionForm = ({ isDetail, institutionIn, institution_id }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { keycloak } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const [institution, setInstitution] = useState({
    name: "",
    ruc: "",
    institution_type: "",
    address: {},
    contacts: [],
  });

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    console.log(institution);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const updateDatosGenerales = (datosGenerales) => {
    setInstitution({ ...institution, ...datosGenerales });
  };

  const updateDatosUbicacion = (datosUbicacion) => {
    setInstitution({
      ...institution,
      address: { ...institution.address, ...datosUbicacion },
    });
  };

  const updateDatosContactos = (datosContactos) => {
    setInstitution({ ...institution, contacts: datosContactos });
  };

  const handleSave = () => {
    console.log("Datos guardados:", institution);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/institutions",
        institution,
        prepareHeaders()
      )
      .then((response) => {
        console.log(response);
        toast.success("Datos guardados correctamente", {
          position: "top-center",
        });
      })
      .catch((error) => console.error(error));
  };

  const createSteps = () => {
    return [
      { label: "Datos generales", code: "1", id: 1 },
      { label: "Datos de ubicación", code: "2", id: 2 },
      { label: "Datos de contacto", code: "3", id: 3 },
    ];
  };

  const steps = createSteps();

  const findInstitution = (doc_id) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/institutions?page=1&pageSize=20&institution_id=" +
          doc_id,
        prepareHeaders()
      )
      .then((res) => {
        if (res.data) {
          console.log(res.data.content[0]);

          setInstitution(res.data.content[0]);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    if (institution_id !== undefined) {
      findInstitution(institution_id);
    }
  }, [institution_id]);

  useEffect(() => {
    if (institutionIn || isDetail) {
      setInstitution(institutionIn);
    }
  }, [institutionIn]);

  return (
    <>
      <Toaster />
      <div className={styles.container}>
        <Stepper steps={steps} current={currentStep}>
          <Step step={1}>
            <DatosGenerales
              nextStep={handleNextStep}
              updateDatosGenerales={updateDatosGenerales}
              isDetail={isDetail}
              institution={institution}
            />
          </Step>
          <Step step={2}>
            <DatosUbicacion
              prevStep={handlePrevStep}
              nextStep={handleNextStep}
              updateDatosUbicacion={updateDatosUbicacion}
              isDetail={isDetail}
              institution={institution}
            />
          </Step>
          <Step step={3}>
            <DatosContacto
              prevStep={handlePrevStep}
              updateDatosContactos={updateDatosContactos}
              handleSave={handleSave}
              isDetail={isDetail}
              institution={institution}
            />
          </Step>
        </Stepper>
      </div>
    </>
  );
};

export default InstitucionForm;
