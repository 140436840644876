import React, { useState, useEffect } from "react";
import {
  Badge,
  Tab,
  Nav,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import styles from "./Crimes.module.scss";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

const CrimesModal = ({
  showModal,
  handleClose,
  data,
  updateSelectedCrimes,
  crimesSelected,
}) => {
  const [selectedCrimes, setSelectedCrimes] = useState([]);
  const [crimes, setCrimes] = useState([]);
  const { keycloak } = useKeycloak();
  console.log(crimes);

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findCrimes = (selected) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/crimes", prepareHeaders())
      .then((res) => {
        if (res.status === 200) {
          setCrimes(mapCrimes(res.data, selected));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const mapCrimes = (rawCrimes, selected) => {
    const mappedCrimes = groupBy(rawCrimes, (rawCrime) => rawCrime.law, "id");
    let crimesFormatted = [];
    for (let key in mappedCrimes) {
      crimesFormatted.push({
        law: JSON.parse(key),
        crimes: mappedCrimes[key].map((ctx) => {
          return { ...ctx, selected: checkSelected(ctx, selected) };
        }),
      });
    }
    return crimesFormatted;
  };

  const checkSelected = (crime, selected) => {
    if (selected && selected.length > 0) {
      for (let current of selected) {
        if (current.id === crime.id) {
          return true;
        }
      }
    }
    return false;
  };

  const groupBy = (list, keyGetter, identifierKey) => {
    const map = new Map();
    const formattedMap = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key[identifierKey]);
      const formattedCollection = formattedMap.get(JSON.stringify(key));
      if (!collection) {
        map.set(key[identifierKey], [item]);
        formattedMap.set(JSON.stringify(key), [item]);
      } else {
        collection.push(item);
        formattedCollection.push(item);
      }
    });
    return Object.fromEntries(formattedMap);
  };

  const handleSelectCrime = (e, crime) => {
    e.preventDefault();
    console.log(crime);
    
    let aux = [];
    let selectedAux = [];
    for (let current of crimes) {
      let auxCrimes = [];
      for (let currentCrime of current.crimes) {
        if (currentCrime.id === crime.id) {
          currentCrime.selected = !currentCrime.selected;
        }
        auxCrimes.push(currentCrime);
      }
      if (auxCrimes.filter((auxCrime) => auxCrime.selected).length > 0) {
        selectedAux.push(...auxCrimes.filter((auxCrime) => auxCrime.selected));
      }
      aux.push({ law: current.law, crimes: auxCrimes });
    }
    console.log(JSON.stringify(selectedAux) + "UPDATE HANDLESELECTCRIME");
    setSelectedCrimes(selectedAux);
    updateSelectedCrimes(selectedAux);
    setCrimes(aux);
  };

  useEffect(() => {
    if (showModal) {
      findCrimes(selectedCrimes);
    }
    if (data) {
      setSelectedCrimes(data.crimes);
      findCrimes(data.crimes);
    }
    if (crimesSelected) {
      setSelectedCrimes(crimesSelected);
      findCrimes(crimesSelected);
    }
  }, [showModal]);

  // useEffect(() => {
  //     findCrimes([]);
  // }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Hechos punibles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {crimes.length > 0 ? (
          <Tab.Container defaultActiveKey={crimes[0].law.id}>
            <Row>
              <Col sm={3}>
                <Nav
                  variant="pills"
                  className={`flex-column ${styles.pillsGroup}`}
                >
                  {crimes.map((crime) => (
                    <Nav.Item className={styles.pill} key={crime.law.id}>
                      <Nav.Link
                        className={styles.pillLink}
                        eventKey={crime.law.id}
                      >
                        <span
                          className={styles.lawNumberSpan}
                        >{`Ley Nº ${crime.law.number}`}</span>
                        <br />
                        <span className={styles.lawNameSpan}>
                          {crime.law.name}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Form style={{ marginBottom: "2vh" }}>
                  <Form.Group controlId="filterName">
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      onChange={(e) => {
                        const filteredCrimes = crimes.map((crime) => ({
                          ...crime,
                          crimes: crime.crimes.map((punishableFact) => ({
                            ...punishableFact,
                            visible: punishableFact.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase()),
                          })),
                        }));
                        setCrimes(filteredCrimes);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="filterArticle">
                    <Form.Control
                      type="text"
                      placeholder="Enter article"
                      style={{ marginBottom: "1vh", marginTop: "1vh" }}
                      onChange={(e) => {
                        const filteredCrimes = crimes.map((crime) => ({
                          ...crime,
                          crimes: crime.crimes.map((punishableFact) => ({
                            ...punishableFact,
                            visible: punishableFact.article
                              .toString()
                              .includes(e.target.value),
                          })),
                        }));
                        setCrimes(filteredCrimes);
                      }}
                    />
                  </Form.Group>
                </Form>
                <Tab.Content>
                  {crimes.map((crime) => (
                    <Tab.Pane eventKey={crime.law.id} key={crime.law.id}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Artículo</th>
                            <th>Nombre</th>
                            <th>Seleccionado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {crime.crimes
                            .filter(
                              (punishableFact) =>
                                punishableFact.visible !== false
                            )
                            .sort((a, b) => b.selected - a.selected)
                            .map((punishableFact) => (
                              <tr
                                key={punishableFact.id}
                                onClick={(e) =>
                                  handleSelectCrime(e, punishableFact)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>{punishableFact.article}</td>
                                <td>{punishableFact.name}</td>
                                <td>
                                  <Badge
                                    className={
                                      punishableFact.selected
                                        ? styles.badgeSelected
                                        : styles.badgeDefault
                                    }
                                  >
                                    {punishableFact.selected ? "Sí" : "No"}
                                  </Badge>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CrimesModal;
