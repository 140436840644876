import { Table } from "react-bootstrap";
import styles from "./ComplainsGrid.module.css";
import { FiTrash } from "react-icons/fi";
import { RiEditBoxLine } from "react-icons/ri";
import React, { useEffect } from "react";
const ComplainsGrid = ({
  person,
  deleteAgents,
  setMatriculaNumber,
  setSelectedPerson,
  setSelectedMatricula,
}) => {
  useEffect(() => {}, [person?.agents]);

  return (
    <>
      <Table className={styles.table} style={{ marginTop: "4vh" }}>
        <thead>
          <th>Nombres y apellidos</th>
          <th>Tipo de documento</th>
          <th>Número de documento</th>
          <th>Matrícula</th>
          <th>Acciones</th>
        </thead>
        <tbody>
          {person &&
            person.agents &&
            Array.isArray(person.agents) &&
            person.agents.map((agent) => (
              <tr key={agent?.documentNumber}>
                <td>{agent?.firstName + agent?.lastName}</td>
                <td>{agent?.documentType}</td>
                <td>{agent?.documentNumber}</td>
                <td>{agent?.matriculaNumber}</td>
                <td>
                  <RiEditBoxLine
                    title="Agregar matrícula"
                    className={styles.icon}
                    onClick={() => {
                      setSelectedMatricula(agent?.matricula ?? "");
                      setSelectedPerson(agent);
                      setMatriculaNumber(true);
                    }}
                  />
                  &nbsp;
                  <FiTrash
                    title="Eliminar"
                    className={styles.icon}
                    onClick={() => deleteAgents(agent?.documentNumber)}
                  />
                  &nbsp;
                </td>
              </tr>
            ))}
          {person && person.agents && !Array.isArray(person.agents) && (
            <tr>
              <td>{person?.firstName + person?.lastName}</td>
              <td>{person?.documentType}</td>
              <td>{person?.documentNumber}</td>
              <td>{person?.matricula}</td>
              <td>
                <RiEditBoxLine
                  title="Agregar matrícula"
                  className={styles.icon}
                  onClick={() => {
                    setSelectedMatricula(person?.matricula ?? "");
                    setSelectedPerson(person);
                    setMatriculaNumber(true);
                  }}
                />
                &nbsp;
                <FiTrash
                  title="Eliminar"
                  className={styles.icon}
                  onClick={() => deleteAgents(person?.documentNumber)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default ComplainsGrid;
