import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Table,
  Col,
  Row,
  Modal,
  Dropdown,
} from "react-bootstrap";
import styles from "./ContraloriaForm.module.scss";
import Person from "./PersonForm";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { FaArchive } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CrimesModal from "../modals/CrimesModal";
import DenunciantesModal from "../DenunciaForm/DenunciantesModal/DenunciantesModal";
import ProsecutorDivisionsModal from "../modals/ProsecutorDivision/ProsecutorDivisionModal";
import { IoPersonAdd } from "react-icons/io5";
import { GoPersonAdd } from "react-icons/go";
import { BiSolidInstitution } from "react-icons/bi";
import InstitucionForm from "../DenunciaForm/institucion/InstitucionForm";
import AddInstitutionModal from "../modals/AddInstitutionModal";
import AddPersonModal from "../modals/AddPersonModal";

const DnitForm = ({ next, previous, data }) => {
  const MAX_LONG = 9007199254740991;
  const contribuyente = {
    PERSON: "person",
    INSTITUTION: "institution",
  };
  const contribuyenteOptions = [
    { value: contribuyente.PERSON, name: "Persona Física" },
    { value: contribuyente.INSTITUTION, name: "Persona Jurídica" },
  ];

  const [validated, setValidated] = useState(false);
  const [preCarga, setPreCarga] = useState(false);
  const navigate = useNavigate();
  const [denunciantes, setDenunciantes] = useState([]);
  const [funcionariosCGR, setFuncionariosCGR] = useState([]);
  const [newFuncionario, setNewFuncionario] = useState(false);
  const [stateInstitutions, setStateInstitutions] = useState([]);
  const [stateInstitution, setStateInstitution] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [telephone, setTelephone] = useState(undefined);
  const [funcionarioModal, setFuncionarioModal] = useState(false);
  const [udeaNro, setUdeaNro] = useState(undefined);
  const [udeaDate, setUdeaDate] = useState(undefined);
  const [fgeNro, setfgeNro] = useState(undefined);
  const [fgeDate, setfgeDate] = useState(undefined);
  const [dnitNro, setDnitNro] = useState(undefined);
  const [dnitDate, setDnitDate] = useState(undefined);
  const [showCrimeModal, setShowCrimeModal] = useState(false);
  const [titleContribuyente, setTitleContribuyente] = useState(undefined);
  const [typeContribuyente, setTypeContribuyente] = useState(
    contribuyente.PERSON
  );

  const [current, setCurrent] = useState(1);

  const handleNextDenunciantes = (data) => {
    setDenunciantes(data);
    setCurrent(current + 1);
  };

  const handlePrevDenunciantes = (data) => {
    setDenunciantes(data);
    setCurrent(current - 1);
  };

  const [department, setDepartment] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [neighborhood, setNeighborhood] = useState(undefined);
  const [principalStreet, setPrincipalStreet] = useState(undefined);
  const [secondaryStreet, setSecondaryStreet] = useState(undefined);
  const [houseNumber, setHouseNumber] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [contribuyenteName, setContribuyenteName] = useState(undefined);
  const [contribuyenteLastName, setContribuyenteLastName] = useState(undefined);
  const [contribuyenteRUC, setContribuyenteRUC] = useState(undefined);
  const [direction, setDirection] = useState(undefined);
  const [possibleDate, setPossibleDate] = useState(undefined);
  const [selectedCrimes, setSelectedCrimes] = useState([]);
  const [amount, setAmount] = useState(undefined);
  const [injuredInstitutions, setInjuredInstitutions] = useState([]);
  const [fact, setFact] = useState(undefined);

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);
  const [crimes, setCrimes] = useState([]);
  const [selectedCrimesShow, setSelectedCrimesShow] = useState(false);

  const patrimonialDamageRule = [
    { lawNumber: "1160/97", article: 187 },
    { lawNumber: "1160/97", article: 192 },
  ];
  const [isAddPerson, setIsAddPerson] = useState(false);
  const [isAddInstitution, setIsAddInstitution] = useState(false);
  const [institution, setInstitution] = useState({
    institution_id: null,
    institution_type: "",
    ruc: null,
    name: "",
    addresses: [],
    contacts: [],
  });
  const [institution_id, setInstitution_id] = useState(null);
  const [person_id, setPerson_id] = useState(null);
  const [selectedInstitutionName, setSelectedInstitutionName] = useState("");
  const [searchInstitutionResult, setSearchInstitutionResult] = useState([]);
  const [institutions, setInstitutions] = useState([
    {
      code: "SALUD",
      label: "Salud",
      selected: false,
    },
    {
      code: "EDUCACION",
      label: "Educación",
      selected: false,
    },
    {
      code: "SERVICIOS_PUBLICOS",
      label: "Servicios públicos",
      selected: false,
    },
  ]);

  const { keycloak } = useKeycloak();

  const [formattedAmount, setFormattedAmount] = useState("");

  // CHANGE: Estados faltantes según contrato swagger
  const [pep, setPep] = useState(false); // boolean
  const [publicOfficial, setPublicOfficial] = useState(false); // boolean
  const [elaborationDate, setElaborationDate] = useState(undefined); // date
  const [receivedAt, setReceivedAt] = useState(undefined); // date-time
  const [fiscalizationType, setFiscalizationType] = useState(undefined); // string
  const [notificationFiscalizationDate, setNotificationFiscalizationDate] =
    useState(undefined); // date
  const [fiscalizationNumber, setFiscalizationNumber] = useState(undefined); // string
  const [showModal, setShowModal] = useState(false);
  const [isCreateInstitution, setIsCreateInstitution] = useState(false);
  const [prosecutorDivisions, setProsecutorDivisions] = useState([]); // Este es el estado en el padre
  const [person, setPerson] = useState({
    id: null,
    documentType: "CI",
    documentNumber: null,
    firstName: "",
    lastName: "",
    birthDate: null,
    gender: "",
    maritalStatus: "",
    profession: "",
    alias: "",

    addresses: [],
    contacts: [],
    personInstitutions: [],
    agents: [],
  });
  const [selectedPerson, setSelectedPerson] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [showModalInstitution, setShowModalInstitution] = useState(false);

  const handleOpenModal = () => {
    // Aquí podrías cargar las divisiones antes de abrir el modal
    // Ej: fetch a la API y setProsecutorDivisions con el resultado
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formatAmount = (value) => {
    const cleanValue = value.replace(/[^\d]/g, "");
    const formatted = parseInt(cleanValue).toLocaleString("es-PY", {
      style: "currency",
      currency: "PYG",
      minimumFractionDigits: 0,
    });
    return formatted;
  };

  const rowHechosPunibles = () => {
    let rows = [
      <Row key="header-crimes">
        <Col className={styles.groupLabel} md="4">
          Artículo
        </Col>
        <Col className={styles.groupLabel} md="4">
          Fecha
        </Col>
        {/* <Col className={styles.groupLabel} md="4">
          Conexidad
        </Col> */}
      </Row>,
    ];
    selectedCrimes.map((crime, index) => {
      rows.push(
        <Row key={index}>
          <Col sm={4}>
            <span
              className={styles.lawNumberSpan}
            >{`Ley Nº ${crime.law.number} ${crime.name}`}</span>
            <br></br>
          </Col>
          <Col sm={4}>
            <Form.Floating>
              <Form.Control
                id={"floatingPossibleDate-" + crime.id}
                type="date"
                required={true}
                value={crime.possibleDate || ""}
                onChange={(e) => handleCrimePossibleDate(e, crime)}
                className={styles.input}
              />
              <Form.Label
                htmlFor={"floatingPossibleDate-" + crime.id}
                className={styles.label}
              >
                Fecha
              </Form.Label>
            </Form.Floating>
          </Col>
          {/* <Col sm={4}>
            <Form.Floating>
              <Form.Check
                type="checkbox"
                name="crimes"
                value={crime.related}
                id={crime.id}
                style={{ accentColor: "#AD0039" }}
                onChange={(e) => handleCheckboxCrimeRelated(e, crime)}
                className={"custom-check"}
                defaultChecked={crime.related}
              />
            </Form.Floating>
          </Col> */}
        </Row>
      );
    });
    return rows;
  };

  const rowFuncionarios = () => {
    let rows = [
      <Row key="header-func">
        <Col className={styles.groupLabel} md="4">
          Nombres y Apellidos
        </Col>
        <Col className={styles.groupLabel} md="4">
          Cargo
        </Col>
        <Col className={styles.groupLabel} md="4">
          Contacto
        </Col>
      </Row>,
    ];
    funcionariosCGR.map((funcionario, index) => {
      rows.push(
        <Row key={index}>
          <Col md="4">{funcionario.firstName + funcionario.lastName}</Col>
          <Col md="4">{funcionario.rol}</Col>
          <Col md="4">
            {funcionario.contact && funcionario.contact.length > 0
              ? funcionario.contact[0].contact_value
              : ""}
          </Col>
        </Row>
      );
    });
    return rows;
  };

  const rowProsecutorDivisions = () => {
    let rows = [
      <Row key="header-prosecutor-divisions">
        <Col className={styles.groupLabel} md="4">
          Unidad Fiscal
        </Col>
        <Col className={styles.groupLabel} md="4">
          Fiscal
        </Col>
      </Row>,
    ];
    prosecutorDivisions.forEach((division, index) => {
      rows.push(
        <Row key={index}>
          <Col md="4">{division.name}</Col>
          <Col md="4">
            {division?.prosecutor?.firstName +
              " " +
              division?.prosecutor?.lastName}
          </Col>
        </Row>
      );
    });

    return rows;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    const numeric = parseInt(inputValue.replace(/[^\d]/g, ""));
    if (isNaN(numeric)) {
      setFormattedAmount("");
    } else if (numeric <= MAX_LONG) {
      const formatted = formatAmount(inputValue);
      setFormattedAmount(formatted);
      setAmount(isNaN(numeric) ? undefined : inputValue.replace(/[^\d]/g, ""));
    } else {
      alert("El máximo valor para el monto es " + MAX_LONG);
    }
  };

  const handleNext = () => {
    next(build());
  };

  const refreshInstitutions = (updated) => {
    let aux = [];
    for (let current of institutions) {
      for (let currentUpdated of updated) {
        if (current.code === currentUpdated.code) {
          current.selected = true;
          break;
        }
      }
      aux.push(current);
    }
    setInstitutions(aux);
  };

  const resetValues = () => {
    setDenunciantes([]);
    setFuncionariosCGR([]);
    setNewFuncionario(false);
    setStateInstitutions([]);
    setStateInstitution(undefined);
    setEmail(undefined);
    setTelephone(undefined);
    setContribuyenteName(undefined);
    setContribuyenteLastName(undefined);
    setContribuyenteRUC(undefined);
    setDirection(undefined);
    setPep(false);
    setPublicOfficial(false);
    setElaborationDate(undefined);
    setReceivedAt(undefined);
    setFiscalizationType(undefined);
    setNotificationFiscalizationDate(undefined);
    setFiscalizationNumber(undefined);
    setProsecutorDivisions([]);
    setSelectedCrimes([]);
    setDnitNro(undefined);
    setDnitDate(undefined);
    setfgeNro(undefined);
    setfgeDate(undefined);
    setUdeaNro(undefined);
    setUdeaDate(undefined);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    // Aquí podrías hacer el POST a la API usando axios.post con el resultado de build()
    // Ejemplo:
    axios
      .post(
        process.env.REACT_APP_API_URL + "/dnit/reports",
        build(),
        prepareHeaders()
      )
      .then((res) => {
        if (res.status === 201) {
          alert("Reporte creado");
        }
        resetValues();
        setTimeout(() => {
          toast.success("Formulario creado exitosamente");
        }, 1000);
        console.log(build());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    previous(build());
  };

  // CHANGE: Ajustamos el build() al contrato DnitReportRequest
  const build = () => {
    return {
      report_dnit_number: dnitNro,
      report_dnit_date: dnitDate,
      tax_payer: {
        ruc: contribuyenteRUC,
        name:
          contribuyenteName +
          (contribuyenteLastName ? " " + contribuyenteLastName : ""),
        fiscal_address: direction,
        taxpayer_type:
          typeContribuyente === contribuyente.PERSON ? "PERSON" : "INSTITUTION",
        institution_id: institution_id,
        person_id: person_id,
      },
      pep: pep,
      public_official: publicOfficial,
      elaboration_date: elaborationDate,
      received_at: receivedAt,
      fiscalization_type: fiscalizationType,
      notification_fiscalization_date: notificationFiscalizationDate,
      fiscalization_number: fiscalizationNumber,
      officials: funcionariosCGR,
      resolution_fge: {
        resolution_fge_number: fgeNro,
        resolution_fge_date: fgeDate,
      },
      resolution_udea: {
        resolution_udea_number: udeaNro,
        resolution_udea_date: udeaDate,
      },
      prosecutors_divisions: prosecutorDivisions.map(({ id, ...division }) => ({
        ...division,
        division_id: id,
      })),
      crimes: selectedCrimes.map(({ id, ...crime }) => ({
        ...crime,
        crime_id: id,
      })),
    };
  };

  const handleSelectCrime = (e, crime) => {
    e.preventDefault();
    let aux = [];
    let selectedAux = [];
    for (let current of crimes) {
      let auxCrimes = [];
      for (let currentCrime of current.crimes) {
        if (currentCrime.id === crime.id) {
          currentCrime.selected = !currentCrime.selected;
        }
        currentCrime.related = false;
        auxCrimes.push(currentCrime);
      }
      if (auxCrimes.filter((auxCrime) => auxCrime.selected).length > 0) {
        selectedAux.push(...auxCrimes.filter((auxCrime) => auxCrime.selected));
      }
      aux.push({ law: current.law, crimes: auxCrimes });
    }
    setSelectedCrimes(selectedAux);
    setCrimes(aux);
  };

  const handleSelectInstitution = (e, institution) => {
    e.preventDefault();
    let aux = [];
    for (let current of institutions) {
      if (current.code === institution.code) {
        current.selected = !current.selected;
      }
      aux.push(current);
    }
    setInjuredInstitutions(aux.filter((current) => current.selected));
    setInstitutions(aux);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        channel: 1,
      },
    };
  };

  const checkPatrimonialDamageRule = () => {
    for (let selected of selectedCrimes) {
      for (let rule of patrimonialDamageRule) {
        if (
          rule.lawNumber === selected.law.number &&
          rule.article === selected.article
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const groupBy = (list, keyGetter, identifierKey) => {
    const map = new Map();
    const formattedMap = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key[identifierKey]);
      const formattedCollection = formattedMap.get(JSON.stringify(key));
      if (!collection) {
        map.set(key[identifierKey], [item]);
        formattedMap.set(JSON.stringify(key), [item]);
      } else {
        collection.push(item);
        formattedCollection.push(item);
      }
    });
    return Object.fromEntries(formattedMap);
  };

  const mapCrimes = (rawCrimes, selected) => {
    const mappedCrimes = groupBy(rawCrimes, (rawCrime) => rawCrime.law, "id");
    let crimesFormatted = [];
    for (let key in mappedCrimes) {
      crimesFormatted.push({
        law: JSON.parse(key),
        crimes: mappedCrimes[key].map((ctx) => {
          return { ...ctx, selected: checkSelected(ctx, selected) };
        }),
      });
    }
    return crimesFormatted;
  };

  const handleCheckboxCrimeRelated = (e, crimeRelated) => {
    const selectedAux = [...selectedCrimes];
    for (let i = 0; i < selectedAux.length; i++) {
      let crimeAux = selectedAux[i];
      if (
        crimeAux.law.id === crimeRelated.law.id &&
        crimeAux.id === crimeRelated.id
      ) {
        selectedAux[i].related = !selectedAux[i].related;
        setSelectedCrimes(selectedAux);
        return;
      }
    }
  };

  const handleCrimePossibleDate = (e, crimeRelated) => {
    const selectedAux = [...selectedCrimes];
    for (let i = 0; i < selectedAux.length; i++) {
      let crimeAux = selectedAux[i];
      if (
        crimeAux.law.id === crimeRelated.law.id &&
        crimeAux.id === crimeRelated.id
      ) {
        selectedAux[i].possibleDate = e.target.value;
        setSelectedCrimes(selectedAux);
        return;
      }
    }
  };

  const checkSelected = (crime, selected) => {
    if (selected && selected.length > 0) {
      for (let current of selected) {
        if (current.id === crime.id) {
          return true;
        }
      }
    }
    return false;
  };

  const findCrimes = (selected) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/crimes", prepareHeaders())
      .then((res) => {
        if (res.status === 200) {
          setCrimes(mapCrimes(res.data, selected));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status === 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => setCities(res.status === 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status === 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const findStateInstitutions = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/state/institutions`,
        prepareHeaders()
      )
      .then((res) => setStateInstitutions(res.status === 200 ? res.data : []))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    findDeparments();
  }, []);
  useEffect(() => findStateInstitutions(), []);
  useEffect(() => {
    if (department) {
      findCities(department.id);
    }
  }, [department]);
  useEffect(() => {
    if (city) {
      findNeighborhoods(city.id);
    }
  }, [city]);

  useEffect(() => {
    if (data) {
      setDepartment(data.address.department);
      setCity(data.address.city);
      setNeighborhood(data.address.neighborhood);
      setPrincipalStreet(data.address.street.principal);
      setSecondaryStreet(data.address.street.secondary);
      setHouseNumber(data.address.houseNumber);
      setDescription(data.address.description);
      setPossibleDate(data.possibleDate);
      setSelectedCrimes(data.crimes);
      setAmount(data.amount);
      setInjuredInstitutions(data.injuredInstitutions);
      setFact(data.fact);

      findCrimes(data.crimes);
      refreshInstitutions(data.injuredInstitutions);
    } else {
      findCrimes([]);
    }
  }, [data]);

  const returnValue = (value) => {
    try {
      return JSON.parse(value).name;
    } catch (e) {
      return value;
    }
  };

  const prepareFileHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  };

  const searchPerson = async () => {
    let uri = "/person?page=1&pageSize=10";
    if (selectedPerson.length > 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            uri +
            `&documentNumber=${selectedPerson}`,
          prepareHeaders()
        )
        .then((res) => {
          setSearchResults(res.data);
        });
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + uri, prepareHeaders())
        .then((res) => {
          setSearchResults(res.data);
        });
    }
  };

  const searchInstitution = () => {
    let uri = "/institutions?page=1&pageSize=10&institution_type=PRIVATE";
    if (selectedInstitutionName.length > 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            uri +
            `&name=${selectedInstitutionName}`,
          prepareHeaders()
        )
        .then((res) => {
          setSearchInstitutionResult(res.data);
        });
      const filteredResults = searchResults.content.filter(
        (person) =>
          person.agent && person.documentNumber === person.agent.documentNumber
      );
      setSearchResults({ ...searchResults, content: filteredResults });
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + uri, prepareHeaders())
        .then((res) => {
          setSearchInstitutionResult(res.data);
        });
    }
  };

  const uploadPerson = async () => {
    if (person.documentNumber.length > 0) {
      createPerson();
    }
    setPerson({ person });
    handleNext();
  };

  const createPerson = () => {
    const URL = `${process.env.REACT_APP_API_URL}/person`;
    axios
      .post(URL, person, prepareHeaders())
      .then((res) => {
        toast.success("Persona registrada exitosamente", {
          position: "top-center",
        });
        setPerson({ ...person, id: res.data.id });
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la persona ", {
          position: "top-center",
        });
      });
  };

  useEffect(() => {
    console.log(institution);
    if (institution && institution.ruc) {
      setContribuyenteRUC(institution.ruc);
      setContribuyenteName(institution.name);
      setIsAddInstitution(false);
      setInstitution_id(institution.institution_id);
    }
  }, [institution]);

  useEffect(() => {
    console.log(person);
    if (person && person.documentNumber) {
      setContribuyenteRUC(person.documentNumber);
      setContribuyenteName(person.firstName);
      setContribuyenteLastName(person.lastName);
      setPerson_id(person.personId);
      setInstitution_id(null);
      setIsAddPerson(false);
    }
  }, [person]);

  useEffect(() => {
    searchPerson();
  }, [selectedPerson]);
  useEffect(() => {
    searchInstitution();
  }, [selectedInstitutionName]);

  useEffect(() => {
    if (showModal == false && showModalInstitution == false) {
      setSelectedPerson("");
      setSelectedInstitutionName("");
    }
  }, [showModal, showModalInstitution]);

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          <h3>Reporte elaborado por DNIT</h3>
        </Form.Label>
      </Row>
      <Form.Label className={styles.groupLabel}>
        <h4>DATOS GENERALES</h4>
      </Form.Label>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Datos FGE</Form.Label>
        <Row>
          <Form.Group as={Col} controlId="notaFGEValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingFGENro"
                type="text"
                value={fgeNro}
                className={styles.input}
                placeholder="N°"
                onChange={(e) => setfgeNro(e.target.value)}
              />
              <Form.Label htmlFor="floatingFGENro" className={styles.label}>
                Nota FGE/N°
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col} controlId="dateFgeValidation">
            <Form.Floating>
              <Form.Control
                id="floatingFGEDate"
                type="date"
                required={true}
                value={fgeDate}
                onChange={(e) => setfgeDate(e.target.value)}
                className={styles.input}
              />
              <Form.Label htmlFor="floatingFGEDate" className={styles.label}>
                Fecha FGE
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Datos UDEA</Form.Label>
        <Row>
          <Form.Group as={Col} controlId="notaUdeaValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingUdeaNro"
                type="text"
                value={udeaNro}
                className={styles.input}
                placeholder="N°"
                onChange={(e) => setUdeaNro(e.target.value)}
              />
              <Form.Label htmlFor="floatingUdeaNro" className={styles.label}>
                Nota UDEA/N°
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col} controlId="dateUdeaValidation">
            <Form.Floating>
              <Form.Control
                id="floatingUdeaDate"
                type="date"
                required={true}
                value={udeaDate}
                onChange={(e) => setUdeaDate(e.target.value)}
                className={styles.input}
              />
              <Form.Label htmlFor="floatingUdeaDate" className={styles.label}>
                Fecha UDEA
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>

      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Datos DNIT</Form.Label>
        <Row>
          <Form.Group as={Col} controlId="notaDNITValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingDNITNro"
                type="text"
                value={dnitNro}
                className={styles.input}
                placeholder="N°"
                onChange={(e) => setDnitNro(e.target.value)}
              />
              <Form.Label htmlFor="floatingDNITNro" className={styles.label}>
                Nota DNIT/N°
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col} controlId="dateDNITValidation">
            <Form.Floating>
              <Form.Control
                id="floatingDNITDate"
                type="date"
                placeholder="dd/mm/yyyy"
                required={true}
                value={dnitDate}
                onChange={(e) => setDnitDate(e.target.value)}
                className={styles.input}
              />
              <Form.Label htmlFor="floatingDNITDate" className={styles.label}>
                Fecha DNIT
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>

      {/* Campos extra del contrato */}
      {/* <Row className="mt-2 mb-4">
                <Form.Label className={styles.groupLabel}>Datos Adicionales del Reporte</Form.Label>            
                <Row >
                    <Form.Group as={Col}>
                        <Form.Floating>
                            <Form.Control
                                type="date"
                                value={elaborationDate || ''}
                                onChange={e => setElaborationDate(e.target.value)}
                                className={styles.input}
                            />
                            <Form.Label>Fecha de elaboración</Form.Label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Floating>
                            <Form.Control
                                type="datetime-local"
                                value={receivedAt || ''}
                                onChange={e => setReceivedAt(e.target.value)}
                                className={styles.input}
                            />
                            <Form.Label>Recibido en fecha/hora</Form.Label>
                        </Form.Floating>
                    </Form.Group>
                </Row>
                <Row className="mt-2">
                    <Form.Group as={Col}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                value={fiscalizationType || ''}
                                onChange={e => setFiscalizationType(e.target.value)}
                                className={styles.input}
                            />
                            <Form.Label>Tipo de Fiscalización</Form.Label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Floating>
                            <Form.Control
                                type="date"
                                value={notificationFiscalizationDate || ''}
                                onChange={e => setNotificationFiscalizationDate(e.target.value)}
                                className={styles.input}
                            />
                            <Form.Label>Fecha de notificación de fiscalización</Form.Label>
                        </Form.Floating>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                value={fiscalizationNumber || ''}
                                onChange={e => setFiscalizationNumber(e.target.value)}
                                className={styles.input}
                            />
                            <Form.Label>N° de Fiscalización</Form.Label>
                        </Form.Floating>
                    </Form.Group>
                </Row>
                
            </Row> */}

      {/* CreatePersonModal */}
      <DenunciantesModal
        showModal={isCreate}
        onHide={() => {
          setIsCreate(false);
          setPerson({});
        }}
        setModal={setShowModal}
        setPerson={setPerson}
        person={person}
        person_id={person.id}
      />

      {/* Add Denunciante Modal */}
      <AddPersonModal
        title={"Agregar Denunciante"}
        complainants={person}
        setComplainants={setPerson}
        onHide={() => setIsAddPerson(false)}
        setPerson={setPerson}
        show={isAddPerson}
        setIsCreate={setIsCreate}
        setShowModal={setShowModal}
      />

      {/* Add InstitutionModal */}
      <AddInstitutionModal
        show={isAddInstitution}
        complainants={institution}
        onHide={() => {
          setIsAddInstitution(false);
        }}
        setComplainants={setInstitution}
        setIsCreate={setIsCreateInstitution}
      />

      {/* createInstitutionModal */}
      <Modal
        size="lg"
        show={isCreateInstitution}
        onHide={() => setIsCreateInstitution(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Persona</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstitucionForm  isDetail={false} />
        </Modal.Body>
      </Modal>

      <Form.Label className={styles.groupLabel}>
        <h4>DATOS DEL CONTRIBUYENTE</h4>
      </Form.Label>

      <Row className="mb-4">
        <Form.Group as={Col}>
          {contribuyenteOptions.map((current) => (
            <Form.Check type="radio" key={current.value}>
              <Form.Check.Input
                id={current.name}
                name="group1"
                required
                type="radio"
                onChange={(e) => {
                  setTypeContribuyente(e.target.value);
                  setContribuyenteLastName("");
                  setContribuyenteName("");
                  setContribuyenteRUC("");
                }}
                value={current.value}
                defaultChecked={typeContribuyente === current.value}
                className={styles.checkbox}
              />
              <Form.Check.Label
                htmlFor={current.name}
                className={styles.checkboxLabel}
              >
                {current.name}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </Form.Group>
      </Row>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ display: "flex", justifyContent: "end" }}
          bsPrefix={styles.next}
          onClick={async () => {
            if (typeContribuyente === "person") {
              await searchPerson();
              setIsAddPerson(true);
            } else {
              await searchInstitution();
              setIsAddInstitution(true);
            }
          }}
        >
          Agregar
        </Button>
      </div>

      {typeContribuyente === contribuyente.PERSON ? (
        <Row className="mb-4">
          <Form.Group as={Col} md={4} controlId="contribuyenteNameValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingContribuyenteNombres"
                type="text"
                value={contribuyenteName || ""}
                className={styles.input}
                placeholder="Nombres"
                onChange={(e) => setContribuyenteName(e.target.value)}
              />
              <Form.Label
                htmlFor="floatingContribuyenteNombres"
                className={styles.label}
              >
                Nombres
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group
            as={Col}
            md={4}
            controlId="contribuyenteLastNameValidation"
          >
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingContribuyenteLastName"
                type="text"
                value={contribuyenteLastName || ""}
                className={styles.input}
                placeholder="Apellidos"
                onChange={(e) => setContribuyenteLastName(e.target.value)}
              />
              <Form.Label
                htmlFor="floatingContribuyenteLastName"
                className={styles.label}
              >
                Apellidos
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      ) : (
        <Row className="mb-4">
          <Form.Group as={Col} controlId="contribuyenteNameValidation" md={4}>
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingContribuyenteNombres"
                type="text"
                value={contribuyenteName || ""}
                className={styles.input}
                placeholder="Nombres"
                onChange={(e) => setContribuyenteName(e.target.value)}
              />
              <Form.Label
                htmlFor="floatingContribuyenteNombres"
                className={styles.label}
              >
                Nombre
              </Form.Label>
            </Form.Floating>
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>
      )}

      <Row className="mb-4">
        <Form.Group as={Col} md={4} controlId="contribuyenteRUCValidation">
          <Form.Floating>
            <Form.Control
              required={false}
              id="floatingContribuyenteRUC"
              type="text"
              value={contribuyenteRUC || ""}
              className={styles.input}
              placeholder="Ingrese el RUC"
              onChange={(e) => setContribuyenteRUC(e.target.value)}
            />
            <Form.Label
              htmlFor="floatingContribuyenteRUC"
              className={styles.label}
            >
              RUC
            </Form.Label>
          </Form.Floating>
        </Form.Group>
      </Row>
      <Row className="mt-2">
        <Form.Group as={Col} md={4}>
          <Form.Check
            type="checkbox"
            label="PEP (Persona Expuesta Políticamente)"
            checked={pep}
            onChange={(e) => setPep(e.target.checked)}
            className={styles.checkboxLabel}
          />
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Check
            type="checkbox"
            label="Funcionario Público"
            checked={publicOfficial}
            onChange={(e) => setPublicOfficial(e.target.checked)}
            className={styles.checkboxLabel}
          />
        </Form.Group>
      </Row>
      <Row className="mt-2 mb-4">
        <Form.Label className={styles.groupLabel}>Domicilio Fiscal</Form.Label>
        <Row>
          <Form.Group as={Col} controlId="directorValidation">
            <Form.Floating>
              <Form.Control
                required={false}
                id="floatingDirector"
                type="text"
                value={direction || ""}
                className={styles.input}
                placeholder="Director"
                onChange={(e) => setDirection(e.target.value)}
              />
              <Form.Label htmlFor="floatingDirector" className={styles.label}>
                Ingrese el domicilio
              </Form.Label>
            </Form.Floating>
          </Form.Group>
        </Row>
      </Row>

      <Form.Label className={styles.groupLabel}>
        <h4>MONTO</h4>
      </Form.Label>
      <Row className="mb-4">
        <Form.Group as={Col} md={4}>
          <Form.Floating>
            <Form.Control
              id="floatingAmount"
              type="text"
              required={!preCarga}
              placeholder="Ingrese el monto estimado del daño"
              className={styles.input}
              value={formattedAmount}
              onChange={handleChange}
            />
            <Form.Label htmlFor="floatingAmount" className={styles.label}>
              Monto
            </Form.Label>
          </Form.Floating>
        </Form.Group>
      </Row>

      <Form.Label className={styles.groupLabel}>
        <h4>HECHOS PUNIBLES</h4>
      </Form.Label>
      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          Hechos punibles{" "}
          <FaArchive onClick={() => setShowCrimeModal(!showCrimeModal)} />
        </Form.Label>
      </Row>

      <CrimesModal
        showModal={showCrimeModal}
        handleClose={(e) => setShowCrimeModal(false)}
        crimesSelected={selectedCrimes}
        updateSelectedCrimes={setSelectedCrimes}
      />

      {selectedCrimes.length > 0 ? rowHechosPunibles() : <></>}

      {funcionarioModal ? (
        <div className={styles.modalCenter}>
          <Person
            show={funcionarioModal}
            handleClose={() => setFuncionarioModal(false)}
            next={handleNextDenunciantes}
            previous={handlePrevDenunciantes}
            complainants={denunciantes}
            funcionarios={funcionariosCGR}
            setFuncionarios={setFuncionariosCGR}
            setComplainants={setDenunciantes}
            setNewFuncionario={setNewFuncionario}
          />
        </div>
      ) : (
        <></>
      )}
      <br />

      <ProsecutorDivisionsModal
        show={showModal}
        handleClose={handleCloseModal}
        prosecutorDivisions={prosecutorDivisions}
        setProsecutorDivisions={setProsecutorDivisions}
      />

      <Form.Label className={styles.groupLabel}>
        <h4>FUNCIONARIOS AUDITORES INTERVINIENTES</h4>
      </Form.Label>
      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          Funcionarios{" "}
          <IoIosAddCircle onClick={() => setFuncionarioModal(true)} />
        </Form.Label>
      </Row>
      {funcionariosCGR.length > 0 ? rowFuncionarios() : <></>}

      {/* Podríamos agregar campos para Prosecutors Divisions aquí, si es necesario capturarlos en UI.
                Por ahora se asume se setearan programaticamente o se agregaría otro modal similar a funcionarios.
            */}

      <Form.Label className={styles.groupLabel}>
        <h4>UNIDADES FISCALES ASIGNADAS</h4>
      </Form.Label>
      <Row className="mb-4">
        <Form.Label className={styles.groupLabel}>
          Unidad Fiscal <IoIosAddCircle onClick={handleOpenModal} />
        </Form.Label>
      </Row>
      {prosecutorDivisions.length > 0 ? rowProsecutorDivisions() : <></>}

      <Row>
        <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
          <Button bsPrefix={styles.next} type="submit">
            GUARDAR
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DnitForm;
