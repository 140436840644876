import React, { useState } from "react";
import {
  Modal,
  Button,
  Row,
  Dropdown,
  Form,
  Table,
  Pagination,
  Col,
} from "react-bootstrap";
import styles from "./AddInstitutionModal.module.css";
import { GoPersonAdd } from "react-icons/go";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useEffect } from "react";
import { BiSolidInstitution } from "react-icons/bi";
import { MdCleaningServices } from "react-icons/md";

const AddInstitutionModal = ({
  show,
  onHide,
  setIsCreate,
  setComplainants,
  complainants,
}) => {
  const [searchResults, setSearchResults] = useState({ content: [] });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const { keycloak } = useKeycloak();

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const searchInstitution = () => {
    let uri = `/institutions?page=${page}&pageSize=10&institution_type=PRIVATE`;
    if (selectedDocument.length > 0) {
      uri = uri + `&ruc=${selectedDocument}`;
    }
    if (selectedName.length > 0) {
      uri = uri + `&name=${toTitleCase(selectedName)}`;
    }

    axios
      .get(process.env.REACT_APP_API_URL + uri, prepareHeaders())
      .then((res) => {
        setSearchResults(res.data);
        setTotalPages(res.data.totalPages);
      });
  };

  useEffect(() => {
    searchInstitution();
  }, [selectedDocument, selectedName, page]);
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Institución</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-4">
          <Dropdown>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Control
                type="text"
                placeholder="Buscar por nombre de institución"
                style={{ width: "40vh" }}
                value={selectedName}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedName(e.target.value);
                }}
              />
              <Form.Control
                type="text"
                placeholder="Buscar por número de RUC"
                style={{ width: "40vh" }}
                value={selectedDocument}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedDocument(e.target.value);
                }}
              />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => {
                    setSelectedName("");
                    setSelectedDocument("");
                  }}
                  bsPrefix={styles.next}
                >
                  Limpiar <MdCleaningServices />
                </Button>
                <Button
                  onClick={() => {
                    setIsCreate(true);
                  }}
                  bsPrefix={styles.next}
                >
                  Nueva Institucion <GoPersonAdd />
                </Button>
              </div>
            </div>
            <Table className={styles.table} style={{ marginTop: "1rem" }}>
              <thead>
                <th>Nombre</th>
                <th>Tipo de Institución</th>
                <th>Número de RUC</th>
                <th></th>
              </thead>
              <tbody>
                {searchResults.content &&
                  searchResults.content.length > 0 &&
                  searchResults.content.map((institutionSelected) => (
                    <tr>
                      <td>{`${institutionSelected.name}`}</td>
                      <td>{institutionSelected.institution_type}</td>
                      <td>{institutionSelected.ruc}</td>
                      <td>
                        <BiSolidInstitution
                          onClick={(e) => {
                            e.preventDefault();
                            if(Array.isArray(complainants)){
                              setComplainants([
                                ...complainants,
                                institutionSelected,
                              ]);
                            }else{
                              setComplainants(institutionSelected);
                            }
                            setSelectedName("");
                            setSelectedDocument("");
                            onHide();
                          }}
                          title="Agregar"
                          className={styles.icon}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Dropdown>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev
                onClick={() => page > 1 && setPage(page - 1)}
                disabled={page === 1}
              >
                Anterior
              </Pagination.Prev>

              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === page}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}

              <Pagination.Next
                onClick={() => page < totalPages && setPage(page + 1)}
                disabled={page === totalPages || totalPages === 0}
              >
                Siguiente
              </Pagination.Next>
            </Pagination>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddInstitutionModal;
