import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import styles from "./ContraloriaForm.module.scss";
import InstitucionForm from "../DenunciaForm/institucion/InstitucionForm";
import { useState } from "react";
import DenunciantesModal from "../DenunciaForm/DenunciantesModal/DenunciantesModal";

const ReportCard = ({
  udeaNro,
  udeaDate,
  fgeNro,
  fgeDate,
  cgrNro,
  cgrDate,
  ReportId,
  amount,
  institution,
  persona,
  isDnit,
}) => {
  const [isCreateInstitution, setIsCreateInstitution] = useState(false);
  const [Institution, setInstitution] = useState({});
  const [person, setPerson] = useState({});
  const [isCreate, setIsCreate] = useState(false);
  return (
    <>
      {/* createInstitutionModal */}
      <Modal
        size="lg"
        show={isCreateInstitution}
        onHide={() => {
          setIsCreateInstitution(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Institucion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstitucionForm
            institutionIn={Institution}
            institution_id={Institution.institution_id}
            isDetail={true}
          />
        </Modal.Body>
      </Modal>

      {/* CreatePersonModal */}
      <DenunciantesModal
        showModal={isCreate}
        onHide={() => {
          setIsCreate(false);
        }}
        person={person}
        setPerson={setPerson}
        person_id={person.id}
        isDetail={true}
      />

      <Card
        className={styles.featureGroup}
        style={{ marginTop: "2vh", marginBottom: "2vh" }}
      >
        <Card.Body>
          <Row>
            <Form.Label className={styles.groupLabelTitle}>
              Nº {ReportId}
            </Form.Label>
          </Row>
          <Row>
            <Form.Label className={styles.groupLabelTitle}>
              Datos FGE
            </Form.Label>

            <Row>
              <Form.Group as={Col} controlId="notaFGEValidation">
                <Form.Label htmlFor="floatingFGENro" className={styles.label}>
                  Nota FGE/N°: {fgeNro ?? "-"}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="dateFgeValidation">
                <Form.Label
                  htmlFor="fgeDate"
                  className={styles.label}
                ></Form.Label>
                <Form.Label htmlFor="floatingFGEDate" className={styles.label}>
                  Fecha FGE: {fgeDate ?? "-"}
                </Form.Label>
              </Form.Group>
            </Row>
          </Row>
          <Row>
            <Form.Label className={styles.groupLabelTitle}>
              Datos UDEA
            </Form.Label>

            <Row>
              <Form.Group as={Col} controlId="notaUdeaValidation">
                <Form.Label htmlFor="floatingUdeaNro" className={styles.label}>
                  Nota UDEA/N°: {udeaNro ?? "-"}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="dateUdeaValidation">
                <Form.Label htmlFor="floatingUdeaDate" className={styles.label}>
                  Fecha UDEA {udeaDate ?? "-"}
                </Form.Label>
              </Form.Group>
            </Row>
          </Row>

          <Row>
            <Form.Label className={styles.groupLabelTitle}>
              Datos {isDnit ? "DNIT" : "CGR"}
            </Form.Label>

            <Row>
              <Form.Group as={Col} controlId="notaCGRValidation">
                <Form.Label htmlFor="floatingCGRNro" className={styles.label}>
                  Nota {isDnit ? "DNIT" : "CGR"}/N° {cgrNro ?? "-"}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="dateCGRValidation">
                <Form.Label htmlFor="floatingCGRDate" className={styles.label}>
                  Fecha {isDnit ? "DNIT" : "CGR"} {cgrDate ?? "-"}
                </Form.Label>
              </Form.Group>
            </Row>
          </Row>

          <Row>
            <Form.Label className={styles.groupLabelTitle}>Monto</Form.Label>

            <Row>
              <Form.Group as={Col} controlId="dateCGRValidation">
                <Form.Label htmlFor="floatingCGRDate" className={styles.label}>
                  Gs. {amount ?? "-"}
                </Form.Label>
              </Form.Group>
            </Row>
          </Row>

          <Row>
            <Form.Label className={styles.groupLabelTitle}>
              Institucion sometida a la actividad de control de la{" "}
              {isDnit ? "DNIT" : "CGR"}
            </Form.Label>

            {institution && (
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>
                      {institution.institution_id ? "Institucion" : "Persona"}
                    </th>
                    {/* <th>Departamento</th>
                                                        <th>Ciudad</th>
                                                        <th>Barrio</th> */}
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {institution &&
                    institution.lenght > 0 &&
                    institution.map((institution) => (
                      <tr>
                        <td>{`${institution.name ?? "-"}`}</td>
                        <td align="center">
                          <Button
                            onClick={(e) => {
                              if (institution.institution_id) {
                                setInstitution(institution);
                                setIsCreateInstitution(true);
                              } else {
                                setPerson(institution);
                                setIsCreate(true);
                              }
                            }}
                            bsPrefix={styles.iconBtn}
                          >
                            Ver
                          </Button>
                        </td>
                      </tr>
                    ))}
                  {institution && !Array.isArray(institution) && (
                    <tr>
                      <td>{`${institution.name ?? "-"}`}</td>
                      <td align="center">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            if (institution.institution_id) {
                              setInstitution(institution);
                              setIsCreateInstitution(true);
                            } else {
                              setPerson(institution);
                              setIsCreate(true);
                            }
                          }}
                          bsPrefix={styles.iconBtn}
                        >
                          Ver
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}

            {/* {person && (
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Tipo de documento</th>
                    <th>Numero de documento</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {person &&
                    person.lenght > 0 &&
                    person.map((person) => (
                      <tr>
                        <td>{`${person.first_name ?? "-"}`}</td>
                        <td>{`${person.last_name ?? "-"}`}</td>
                        <td>{`${person.document_type ?? "-"}`}</td>
                        <td>{`${person.document_number ?? "-"}`}</td>
                        <td align="center">
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setPerson(person);
                              setIsCreate(true);
                            }}
                            bsPrefix={styles.iconBtn}
                          >
                            Ver
                          </Button>
                        </td>
                      </tr>
                    ))}
                  {person && !Array.isArray(person) && (
                    <tr>
                      <td>{`${person.first_name ?? "-"}`}</td>
                      <td>{`${person.last_name ?? "-"}`}</td>
                      <td>{`${person.document_type ?? "-"}`}</td>
                      <td>{`${person.document_number ?? "-"}`}</td>
                      <td align="center">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setPerson(person);
                            setIsCreate(true);
                          }}
                          bsPrefix={styles.iconBtn}
                        >
                          Ver
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )} */}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportCard;
