import React, { useEffect, useState } from "react";
import { Modal, Table, Form } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./ProsecutorDivision.module.css";

const ProsecutorDivisionsModal = ({ 
  show, 
  handleClose, 
  prosecutorDivisions, 
  setProsecutorDivisions 
}) => {
  const [divisions, setDivisions] = useState([]);
  const [selectedProsecutorDivisions, setSelectedProsecutorDivisions] = useState([]);
  const { keycloak } = useKeycloak();

  const UpdateListDivisions = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/divisions`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );
      setDivisions(data);
    } catch (error) {
      console.error("Error fetching divisions", error);
    }
  };

  // Al mostrarse el modal, seteamos las divisiones seleccionadas desde el padre
  useEffect(() => {
    if (show) {
      setSelectedProsecutorDivisions(prosecutorDivisions || []);
    }
  }, [show, prosecutorDivisions]);

  // Cuando el modal se muestre, cargamos las divisiones
  useEffect(() => {
    if (show) {
      UpdateListDivisions();
    }
  }, [show]);

  // Cada vez que cambia el estado interno, actualizamos el padre
  useEffect(() => {
    setProsecutorDivisions(selectedProsecutorDivisions);
  }, [selectedProsecutorDivisions, setProsecutorDivisions]);

  const handleCheckboxChange = (division) => {
    const isSelected = selectedProsecutorDivisions.some((div) => div.id === division.id);
    if (isSelected) {
      // Si ya está seleccionado, lo removemos
      setSelectedProsecutorDivisions((prev) =>
        prev.filter((div) => div.id !== division.id)
      );
    } else {
      // De lo contrario, lo agregamos
      setSelectedProsecutorDivisions((prev) => [...prev, division]);
    }
  };

  const isDivisionSelected = (divisionId) => {
    return selectedProsecutorDivisions.some((div) => div.id === divisionId);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Unidades Fiscales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className={styles.table}>
          <thead>
            <tr>
              
              <th>Unidad Fiscal</th>
              <th>Fiscal Asignado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {divisions.map((division) => (
              <tr key={division.id}>
                
                <td>{division.name}</td>
                <td>
                  {division.prosecutor && (
                    <>
                      {division.prosecutor.firstName} {division.prosecutor.lastName}
                    </>
                  )}
                </td>
                <td style={{ width: "50px" }}>
                  <Form.Check
                    type="checkbox"
                    checked={isDivisionSelected(division.id)}
                    onChange={() => handleCheckboxChange(division)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ProsecutorDivisionsModal;
