import Header from './components/Header';
import DenunciasRegistro from './pages/denuncias/DenunciasRegistro';
import ContraloriaRegistro from './pages/contraloria/ContraloriaRegistro';
import CGRConsulta from './pages/contraloria/CGRConsulta';
import DNITConsulta from './pages/dnit/DNITConsulta';
import DnitRegistro from './pages/dnit/DnitRegistro';
import DashboardMesaEntrada from './pages/dashboard/mesa-entrada/DashboardMesaEntrada';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";
import { checkRoles, roles } from "./Keycloak";
import DenunciasConsulta from './pages/denuncias/DenunciasConsulta';

import Admin from "./pages/admin/Admin";
import "./App.css";
function App() {
  const { keycloak } = useKeycloak();
  return (     
    <Routes>
      
      <Route path="/denuncias/consultar" element={
        
        <PrivateRoute allowedRoles={["fiscal-adjunto", "mesa-de-entrada", "ddea", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <DenunciasConsulta />
            </div>
          </div>
         </PrivateRoute>
      } />
      <Route path="/admin" element={
        <PrivateRoute allowedRoles={["admin"]}>
          <div className="App">
            <Header />
            <div className="container">
              <Admin />
            </div>
          </div>
        
        // </PrivateRoute>
      } />      
      <Route path="/denuncias/registrar" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "fiscal-adjunto", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <DenunciasRegistro />
            </div>
          </div>
         
         </PrivateRoute>
      } />
      <Route path="/cgr/registrar" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "fiscal-adjunto", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <ContraloriaRegistro />
            </div>
          </div>
         
         </PrivateRoute>
      } />
      <Route path="/cgr/consultar" element={
        
        <PrivateRoute allowedRoles={["fiscal-adjunto", "mesa-de-entrada", "ddea", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <CGRConsulta />
            </div>
          </div>
         </PrivateRoute>
      } />
      <Route path="/dnit/consultar" element={
        
        <PrivateRoute allowedRoles={["fiscal-adjunto", "mesa-de-entrada", "ddea", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <DNITConsulta />
            </div>
          </div>
         </PrivateRoute>
      } />
      <Route path="/dnit/registrar" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "fiscal-adjunto", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <DnitRegistro />
            </div>
          </div>
         
         </PrivateRoute>
      } />
      <Route exact path="/" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "fiscal", "fiscal-adjunto", "ddea", "asistente-fiscal"]}>
        
          <div className="App">
            <Header />
            <div className="container">
              {dashboard(keycloak)}
            </div>
          </div>  
          </PrivateRoute>
        
      } />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

const dashboard = (keycloak) => {
  if(!roles(keycloak)){
    return <></>
  }
  // if(roles(keycloak).includes("fiscal-adjunto")){
  //   return <DashboardFiscalAdjunto />;
  // }
  // else if (roles(keycloak).includes("fiscal") || roles(keycloak).includes("asistente-fiscal")){
  //   return <DashboardFiscal />
  // }
  if (roles(keycloak).includes("ddea") || roles(keycloak).includes("mesa-de-entrada") || roles(keycloak).includes("external-institution")){
    return <DashboardMesaEntrada />
  }  
  else return <></>;
}

export default App;
