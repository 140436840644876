import React, { useEffect, useState } from "react";
import ContactStep from "./steps/ContactStep";
import AddressStep from "./steps/AddressStep";
import { useKeycloak } from "@react-keycloak/web";
import PersonaStep from "./steps/PersonaStep";
import JobsStep from "./steps/JobsStep";
import styles from "./DenunciantesModal.module.css";
import axios from "axios";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
const DenunciantesModal = ({
  setModal,
  setPerson,
  handleSubmit,
  type,
  setMandatorio,
  mandatorio,
  attachments,
  setAttachments,
  isDenunciado,
  person,
  isDetail,
  person_id,
  showModal,
  onHide,
}) => {
  const [step, setStep] = useState(0);

  const [addresses, setAddresses] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [jobs, setJobs] = useState([]);
  const { keycloak } = useKeycloak();
  const updatePerson = (fieldOrObject, value) => {
    setPerson((prevPerson) => {
      if (typeof fieldOrObject === "object" && fieldOrObject !== null) {
        return {
          ...prevPerson,
          ...fieldOrObject,
        };
      }
      return {
        ...prevPerson,
        [fieldOrObject]: value,
      };
    });
  };

  const updateAddresses = (addresses) => {
    setPerson((prevData) => ({
      ...prevData,
      addresses: addresses,
    }));
  };

  const updateContacts = (contacts) => {
    setPerson((prevData) => ({
      ...prevData,
      contacts: contacts,
    }));
  };

  const updateJobs = (personInstitutions) => {
    setPerson((prevData) => ({
      ...prevData,
      personInstitutions: personInstitutions,
    }));
  };

  const handleSave = () => {
    const URL = `${process.env.REACT_APP_API_URL}/person`;
    axios
      .post(URL, person, prepareHeaders())
      .then((res) => {
        toast.success("Persona registrada exitosamente", {
          position: "top-center",
        });
        setPerson({ ...person, id: res.data.id });
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la persona ", {
          position: "top-center",
        });
        console.log(err);
      });
    setModal(false);
  };

  const nextStep = () => {
    if (step !== 4) {
      setStep((step) => step + 1);
    }
  };

  const prevStep = () => {
    if (step !== 0) {
      setStep((step) => step - 1);
    }
    console.log(step);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findPerson = (doc_id) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/person?page=1&pageSize=20&personId=" +
          doc_id,
        prepareHeaders()
      )
      .then((res) => {
        if (res.data) {
          console.log(res.data.content[0]);

          setPerson(res.data.content[0]);
        }
      });
  };

  useEffect(() => {
    if (person_id) {
      findPerson(person_id);
    }
  }, [person_id]);

  useEffect(() => {
    if (person) {
      if (person.personInstitutions) {
        setJobs(person.personInstitutions);
      }
      if (person.contacts) {
        setContacts(person.contacts);
      }
      if (person.addresses) {
        setAddresses(person.addresses);
      }
    }
  }, [person]);

  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => {
          onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isDetail ? "Detalles de la persona" : "Agregar Persona"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {step === 0 && (
              <PersonaStep
                nextStep={nextStep}
                setRequest={updatePerson}
                isDetail={isDetail}
                person={person}
              />
            )}
            {step === 1 && (
              <AddressStep
                nextStep={nextStep}
                prevStep={prevStep}
                updateAddresses={updateAddresses}
                addresses={addresses}
                setAddresses={setAddresses}
                isDetail={isDetail}
                person={person}
              />
            )}
            {step === 2 && (
              <ContactStep
                updateContacts={updateContacts}
                prevStep={prevStep}
                nextStep={nextStep}
                contacts={contacts}
                setContacts={setContacts}
                isDetail={isDetail}
                person={person}
              />
            )}
            {step === 3 && (
              <JobsStep
                updateJobs={updateJobs}
                prevStep={prevStep}
                handleSave={handleSave}
                jobs={jobs}
                setJobs={setJobs}
                setAttachments={setAttachments}
                attachments={attachments}
                mandatorio={mandatorio}
                setMandatorio={setMandatorio}
                type={type}
                isDenunciado={isDenunciado}
                isDetail={isDetail}
                person={person}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DenunciantesModal;
