import React, { useState } from "react";
import { Form, Button, Col, Row, Table } from "react-bootstrap";
import { FiTrash } from "react-icons/fi";
import styles from "../Institucion.module.css";
import { useEffect } from "react";

const DatosContacto = ({
  prevStep,
  nextStep,
  updateDatosContactos,
  handleSave,
  isDetail,
  institution,
}) => {
  const [contactNumber, setContactNumber] = useState("");
  const [contactType, setContactType] = useState("");
  const [contacts, setContacts] = useState([]);

  const addContact = () => {
    const newContact = {
      contact_number: contactNumber,
      type: contactType,
    };
    setContacts([...contacts, newContact]);
    clearFields();
  };

  const removeContact = (contactToRemove) => {
    setContacts(contacts.filter((contact) => contact !== contactToRemove));
  };

  const clearFields = () => {
    setContactNumber("");
    setContactType("PRIMARIA");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (contacts.length === 0) {
      console.log("No se han agregado contactos");
      return;
    }
    updateDatosContactos(contacts);
    handleSave();
  };

  useEffect(() => {
    if (institution && institution.contacts) {
      setContacts(institution.contacts);
    }
  }, [institution]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Col>
            <Form.Label className={styles.groupLabel}>Contacto</Form.Label>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Floating>
              <Form.Control
                type="text"
                name="contact_number"
                value={contactNumber}
                disabled={isDetail}
                onChange={(e) => setContactNumber(e.target.value)}
                placeholder="Ingrese el número de contacto"
                className={styles.input}
              />
              <label htmlFor="contact_number" className={styles.label}>
                Número de contacto
              </label>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating>
              <Form.Control
                name="contact_type"
                as="select"
                value={contactType}
                disabled={isDetail}
                onChange={(e) => setContactType(e.target.value)}
                className={styles.input}
              >
                <option value="" selected disabled>
                  Seleccione...
                </option>
                <option value="PRIMARIO">Primario</option>
                <option value="SECUNDARIO">Secundario</option>
              </Form.Control>
              <label htmlFor="contact_type" className={styles.label}>
                Tipo de contacto
              </label>
            </Form.Floating>
          </Col>
        </Row>

        {isDetail ? (
          <></>
        ) : (
          <Row className="mt-3">
            <Col>
              <Button bsPrefix={styles.next} type="button" onClick={addContact}>
                GUARDAR CONTACTO
              </Button>
            </Col>
          </Row>
        )}

        {contacts.length > 0 && (
          <Table className={styles.table} style={{ marginTop: "1rem" }}>
            <thead>
              <tr>
                <th>Número de contacto</th>
                <th>Tipo de contacto</th>
                {!isDetail && <th></th>}
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, index) => (
                <tr key={index}>
                  <td>{contact.contact_number}</td>
                  <td>{contact.type}</td>
               {!isDetail && (
                   <td>
                   <FiTrash
                     onClick={() => {
                       if (isDetail) return;
                       removeContact(contact);
                     }}
                     title="Descartar"
                     className={styles.icon}
                   />
                 </td>
               )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Row className="mt-4" style={{ display: "flex", paddingTop: ".7rem" }}>
          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={prevStep}>
              ANTERIOR
            </Button>
          </Col>
          <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
            {isDetail ? (
              <></>
            ) : (
              <Button bsPrefix={styles.next} type="submit">
                GUARDAR
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DatosContacto;
