import React, { useState } from "react";
import { Form, Row, Col, Table, Button, Badge, Modal } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { AiOutlineFileSearch } from "react-icons/ai";
import styles from "./Preview.module.css";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import DocumentType from "../../../constants/DocumentType";
import DenunciantesModal from "../DenunciantesModal/DenunciantesModal";
import { useNavigate } from "react-router-dom";

const Preview = ({ complaint, cancel, setPreview }) => {
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [person, setPerson] = useState({});
  const [isDetail, setIsDetail] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const prepareHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        channel: 1,
      },
    };
  };

  const downloadFile = (e, fileName) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_API_URL}/content/${fileName}`,
      prepareHeaders()
    )
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatAmount = (value) => {
    const formatted = parseInt(value).toLocaleString("es-PY", {
      style: "currency",
      currency: "PYG",
      minimumFractionDigits: 0,
    });
    return formatted;
  };

  const confirm = (e) => {
    e.preventDefault();
    //setLoading(true);
    const complainants = complaintBody();
    const body = {
      ...complainants,
      statementOfFact: {
        ...complaint.statementOfFact,
        relatedInstitutions: complaint.statementOfFact.relatedInstitutions.map(
          (current) => current.code
        ),
      },
    };
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/complaints`, body, prepareHeaders())
          .then(res => {
              toast.success("Denuncia registrada #" + res.data.id);
              navigate('/denuncias/consultar');
          })
          .catch(error => {
              toast.error("Ha ocurrido un error al registrar la denuncia " + error.data ? (": " + error.data.message) : "");
          });
      console.log(body);
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error al registrar la denuncia");
    }
  };

  const back = (e) => {
    e.preventDefault();
    setPreview(false);
  };

  function clearNestedProperties(obj, propertiesToClear) {
    if (Array.isArray(obj)) {
      // Si es un array, iteramos sobre cada elemento
      return obj.map((item) => clearNestedProperties(item, propertiesToClear));
    } else if (obj !== null && typeof obj === "object") {
      // Si es un objeto, iteramos sobre sus claves
      for (const key in obj) {
        if (propertiesToClear.includes(key)) {
          obj[key] = null; // Limpiamos la propiedad si está en la lista
        } else if (
          Array.isArray(obj[key]) ||
          (obj[key] !== null && typeof obj[key] === "object")
        ) {
          // Llamada recursiva si el valor es un array u objeto
          obj[key] = clearNestedProperties(obj[key], propertiesToClear);
        }
      }
    }
    return obj;
  }
  
  function cleanAgentsInEntities(entities) {
    entities.forEach((entity) => {
      if (Array.isArray(entity.agents)) {
        entity.agents = entity.agents.map((agent) => {
          const { personInstitutions, addresses, contacts, ...rest } = agent;
          return rest;
        });
      }
    });
  }
  
  const complaintBody = () => {
    const updatedComplaint = { ...complaint };
  
    const entities = ["complainants", "defendants", "witnesses"];
    const nestedProperties = [
      "personInstitutions",
      "contacts",
      "addresses"
    ];
  
    // Limpieza general de propiedades anidadas
    entities.forEach((entity) => {
      if (Array.isArray(updatedComplaint[entity])) {
        updatedComplaint[entity] = updatedComplaint[entity].map((item) =>
          clearNestedProperties(item, nestedProperties)
        );
  
        // Limpieza específica de agents dentro de cada entidad
        cleanAgentsInEntities(updatedComplaint[entity]);
      } else if (
        updatedComplaint[entity] &&
        typeof updatedComplaint[entity] === "object"
      ) {
        updatedComplaint[entity] = clearNestedProperties(
          updatedComplaint[entity],
          nestedProperties
        );
      } else {
        updatedComplaint[entity] = [];
      }
    });
  
    if (Array.isArray(updatedComplaint.complainants.agents)) {
      updatedComplaint.complainants.agents = updatedComplaint.complainants.agents.map((agent) =>
        clearNestedProperties(agent, [
          "personInstitutions",
          "contacts",
          "addresses",
        ])
      );
    } else if (
      updatedComplaint.complainants.agents &&
      typeof updatedComplaint.complainants.agents === "object"
    ) {
      updatedComplaint.complainants.agents = clearNestedProperties(updatedComplaint.complainants.agents, [
        "personInstitutions",
        "contacts",
        "addresses",
      ]);
    } else {
      updatedComplaint.complainants.agents = [];
    }
  
    return updatedComplaint;
  };
  return complaint ? (
    <>
      <Toaster />
      <Form>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Datos generales
          </Form.Label>
          <Row>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Tipo de denuncia
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.type}
                disabled
              />
            </Col>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Reservada
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.reserved ? "Sí" : "No"}
                disabled
              />
            </Col>
          </Row>
        </Row>
        {complaint.type == "RESOLUCION" ? (
          <Row className={styles.group}>
            <Form.Label className={styles.groupLabel} as={Col}>
              Datos de la resolución
            </Form.Label>
            <Row className="mb-2">
              <Col md={3}>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Número
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={complaint.resolution.number}
                  disabled
                />
              </Col>
              <Col md={9}>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Descripción
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={complaint.resolution.description}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Fiscal asignado
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={
                    complaint.prosecutor.firstName +
                    " " +
                    complaint.prosecutor.lastName
                  }
                  disabled
                />
              </Col>
              <Col md={4}>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Resolución adjunta
                </Form.Label>
                <a
                  download={complaint.resolution.file.fileName}
                  href={complaint.resolution.file.payload}
                >
                  <Badge bg="secondary">
                    {complaint.resolution.file.fileName}
                  </Badge>
                </a>
              </Col>
            </Row>
          </Row>
        ) : (
          <></>
        )}
        {/* CreatePersonModal */}
        <Modal
          size="lg"
          show={isCreate}
          onHide={() => {
            setIsCreate(false);
            setPerson({});
            setIsDetail(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isDetail ? "Detalles de la persona" : "Agregar Persona"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DenunciantesModal
              person={person}
              isDetail={isDetail}
              // selectedPersonId={selectedPersonId}
            />
          </Modal.Body>
        </Modal>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Denunciantes
          </Form.Label>
          {complaint.complainants &&
          complaint.complainants.filter(
            (complaint) => complaint.type == undefined || complaint.type == "PF"
          ).length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Nombres y apellidos</th>
                    <th>Tipo de documento</th>
                    <th>Número de documento</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.complainants
                    .filter(
                      (complaint) =>
                        complaint.type == undefined || complaint.type == "PF"
                    )
                    .map((complainant) => (
                      <tr>
                        <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                        <td>
                          {complainant.documentType
                            ? DocumentType[complainant.documentType]
                                .description
                            : "-"}
                        </td>
                        <td>{complainant.documentNumber}</td>
                        {/* <td>{complainant.birthDate}</td> */}
                        <td>
                          <AiOutlineFileSearch
                            title="Ver"
                            onClick={() => {
                              setPerson(complainant);
                              setIsDetail(true);
                              setIsCreate(true);
                            }}
                            className={styles.icon}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <></>
          )}
          {complaint.complainants &&
          complaint.complainants.filter(
            (complaint) => complaint.type != undefined && complaint.type == "IE"
          ).length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Institución del estado</th>
                    <th>Nombres y apellidos</th>
                    <th>Tipo de documento</th>
                    <th>Número de documento</th>
                    <th>Cargo</th>
                    {/* <th>Departamento</th>
                                            <th>Ciudad</th>
                                            <th>Barrio</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.complainants
                    .filter((complaint) => complaint.type == "IE")
                    .map((complainant) => (
                      <tr>
                        <td>{complainant.stateInstitution.name}</td>
                        <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                        <td>
                          {complainant.documentType
                            ? DocumentType[complainant.documentType]
                                .description
                            : "-"}
                        </td>
                        <td>{complainant.documentNumber}</td>
                        <td>{complainant.title}</td>
                        {/* <td>{complainant.address[0].department ? complainant.address[0].department : "-"}</td>
                                                    <td>{complainant.address[0].city ? complainant.address[0].city : "-"}</td>
                                                    <td>{complainant.address[0].neighborhood ? complainant.address[0].neighborhood : "-"}</td> */}
                        <td>
                          <AiOutlineFileSearch
                            onClick={() => {
                              setPerson(complainant);
                              setIsDetail(true);
                              setIsCreate(true);
                            }}
                            title="Ver"
                            className={styles.icon}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <></>
          )}
          {complaint.complainants &&
          complaint.complainants.filter(
            (complaint) => complaint.type != undefined && complaint.type == "IP"
          ).length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Institución privada</th>
                    <th>Nombres y apellidos</th>
                    <th>Tipo de documento</th>
                    <th>Número de documento</th>
                    {/* <th>Fecha de nacimiento</th>
                                            <th>Departamento</th>
                                            <th>Ciudad</th>
                                            <th>Barrio</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.complainants
                    .filter((complaint) => complaint.type == "IP")
                    .map((complainant) => (
                      <tr>
                        <td>{complainant.privateInstitution}</td>
                        <td>{`${complainant.firstName} ${complainant.lastName}`}</td>
                        <td>
                          {complainant.documentType
                            ? DocumentType[complainant.documentType]
                                .description
                            : "-"}
                        </td>
                        <td>{complainant.documentNumber}</td>
                        <td>{complainant.birthDate}</td>
                        {/* <td>{complainant.addresses[0].department ? complainant.addresses[0].department : "-"}</td> */}
                        {/* <td>{complainant.addresses[0].city ? complainant.addresses[0].city : "-"}</td> */}
                        {/* <td>{complainant.addresses[0].neighborhood ? complainant.addresses[0].neighborhood : "-"}</td> */}
                        <td>
                          <AiOutlineFileSearch
                            title="Ver"
                            onClick={() => {
                              setPerson(complainant);
                              setIsDetail(true);
                              setIsCreate(true);
                            }}
                            className={styles.icon}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row className={styles.group}>
          <Form.Label as={Col} className={styles.groupLabel}>
            Perjudicados
          </Form.Label>
          {complaint.injuredParties && complaint.injuredParties.length > 0 ? (
            <Row>
              {complaint.injuredParties.map((injuredParty) => (
                <Col md="auto" style={{ paddingRight: "0px" }}>
                  <Badge bg="secondary">{injuredParty}</Badge>
                </Col>
              ))}
            </Row>
          ) : (
            <span>-</span>
          )}
        </Row>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Denunciados
          </Form.Label>
          {complaint.defendants && complaint.defendants.length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Nombres y apellidos</th>
                    <th>Tipo de documento</th>
                    <th>Número de documento</th>
                    {/* <th>Fecha de nacimiento</th> */}
                    {/* <th>Departamento</th>
                                            <th>Ciudad</th>
                                            <th>Barrio</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.defendants.map((defendant) => (
                    <tr>
                      <td>{`${defendant.firstName} ${defendant.lastName}`}</td>
                      <td>{defendant.documentType}</td>
                      <td>{defendant.documentNumber}</td>
                      {/* <td>{defendant.birthDate}</td> */}
                      {/* <td>{defendant.addresses[0].department ? defendant.addresses[0].department : "-"}</td>
                                                    <td>{defendant.addresses[0].city ? defendant.addresses[0].city : "-"}</td>
                                                    <td>{defendant.addresses[0].neighborhood ? defendant.addresses[0].neighborhood : "-"}</td> */}
                      <td>
                        <AiOutlineFileSearch
                          title="Ver"
                          onClick={() => {
                            setPerson(defendant);
                            setIsDetail(true);
                            setIsCreate(true);
                          }}
                          className={styles.icon}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <span>-</span>
          )}
        </Row>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Lugar del hecho
          </Form.Label>
          <Row style={{ marginBottom: "1rem" }}>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Departamento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.address.department.name}
                disabled
              />
            </Col>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Ciudad
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.address.city.name}
                disabled
              />
            </Col>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Barrio
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={
                  complaint.statementOfFact.address.neighborhood
                    ? complaint.statementOfFact.address.neighborhood.name
                    : "-"
                }
                disabled
              />
            </Col>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Calle principal
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.address.street.principal}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "1rem" }}>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Calle secundaria
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.address.street.secondary}
                disabled
              />
            </Col>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Número de residencia
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.address.houseNumber}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Descripción
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.address.description}
                disabled
              />
            </Col>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Datos del hecho
          </Form.Label>
          <Row style={{ marginBottom: "1rem" }}>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Posible fecha del hecho
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={complaint.statementOfFact.possibleDate}
                disabled
              />
            </Col>
            <Col>
              <Form.Label bsPrefix={styles.label} as={Col}>
                Relato del hecho
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={
                  complaint.statementOfFact.detailedAccountOfThePunishableAct
                }
                disabled
              />
            </Col>
          </Row>
          {complaint.statementOfFact.crimes ? (
            <Row>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Hechos punibles
                </Form.Label>
                {complaint.statementOfFact.crimes.map((crime) => (
                  <Badge
                    bg="secondary"
                    style={{ marginRight: ".5rem" }}
                  >{`Art. ${crime.article}, ${crime.name}`}</Badge>
                ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Row>
        {complaint.statementOfFact.estimatedAmountOfDamage ||
        complaint.statementOfFact.relatedInstitutions ? (
          <Row className={styles.group}>
            <Form.Label className={styles.groupLabel} as={Col}>
              Daño estimado
            </Form.Label>
            <Row>
              {complaint.statementOfFact.estimatedAmountOfDamage ? (
                <Col>
                  <Form.Label bsPrefix={styles.label} as={Col}>
                    Monto
                  </Form.Label>
                  <Form.Control
                    bsPrefix={styles.input}
                    value={
                      complaint.statementOfFact.estimatedAmountOfDamage.value
                        ? formatAmount(
                            complaint.statementOfFact.estimatedAmountOfDamage
                              .value
                          )
                        : "-"
                    }
                    disabled
                  />
                </Col>
              ) : (
                <></>
              )}
              {complaint.statementOfFact.relatedInstitutions ? (
                <Col>
                  <Form.Label bsPrefix={styles.label} as={Col}>
                    Instituciones afectadas
                  </Form.Label>
                  {complaint.statementOfFact.relatedInstitutions &&
                  complaint.statementOfFact.relatedInstitutions.length > 0 ? (
                    <>
                      {complaint.statementOfFact.relatedInstitutions.map(
                        (institution) => (
                          <Badge
                            bg="secondary"
                            style={{ marginRight: ".5rem" }}
                          >
                            {institution.label}
                          </Badge>
                        )
                      )}
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Row>
        ) : (
          <></>
        )}
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Testigos
          </Form.Label>
          {complaint.witnesses && complaint.witnesses.length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Nombres y Apellidos</th>
                    {/* <th>Fecha de nacimiento</th> */}
                    <th>Tipo de documento</th>
                    <th>Número de documento</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.witnesses.map((witness) => (
                    <tr>
                      <td>{witness.firstName + " " + witness.lastName}</td>
                      {/* <td>{witness.birthDate}</td> */}
                      <td>{witness.documentType}</td>
                      <td>{witness.documentNumber}</td>
                      <td>
                        <AiOutlineFileSearch
                          title="Ver"
                          onClick={() => {
                            setPerson(witness);
                            setIsDetail(true);
                            setIsCreate(true);
                          }}
                          className={styles.icon}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <span>-</span>
          )}
        </Row>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Informes periciales
          </Form.Label>
          {complaint.expertsReports && complaint.expertsReports.length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Archivo</th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.expertsReports.map((expertReport) => (
                    <tr>
                      <td>{expertReport.fileName}</td>
                      <td>{expertReport.fileName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <span>-</span>
          )}
        </Row>
        <Row className={styles.group}>
          <Form.Label className={styles.groupLabel} as={Col}>
            Documentos adjuntos
          </Form.Label>
          {complaint.attachments && complaint.attachments.length > 0 ? (
            <Col md={12}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Ubicación</th>
                    <th>Archivos</th>
                  </tr>
                </thead>
                <tbody>
                  {complaint.attachments.map((attachment) => (
                    <tr>
                      <td>{attachment.type}</td>
                      <td>{attachment.name}</td>
                      <td>{attachment.description}</td>
                      <td>{attachment.location}</td>
                      <td>
                        {attachment.file ? (
                          <button
                            className={styles.iconBtn}
                            onClick={(e) => {
                              downloadFile(e, attachment.file.fileName);
                            }}
                          >
                            {attachment.file.fileName}
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <span>-</span>
          )}
        </Row>
        <Row
          className="mt-4"
          style={{
            display: "flex",
            borderTop: "1px solid silver",
            paddingTop: ".7rem",
          }}
        >
          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={back} disabled={loading}>
              VOLVER
            </Button>
            <Button bsPrefix={styles.next} onClick={cancel} disabled={loading}>
              CANCELAR
            </Button>
          </Col>
          <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
            <Button bsPrefix={styles.next} onClick={confirm} disabled={loading}>
              {loading ? (
                <>
                  <Spinner size="sm" animation="border" role="status" />
                  &nbsp;&nbsp;
                  <span>REGISTRANDO...</span>
                </>
              ) : (
                <span>CONFIRMAR</span>
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    <></>
  );
};

export default Preview;
