import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import styles from "../Institucion.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import TextField from "@mui/material/TextField";

const DatosUbicacion = ({
  prevStep,
  nextStep,
  updateDatosUbicacion,
  isDetail,
  institution,
}) => {
  const [city, setCity] = useState(undefined);
  const [department, setDepartment] = useState(undefined);
  const [neighborhood, setNeighborhood] = useState(undefined);
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [principalStreet, setPrincipalStreet] = useState("");
  const [secondaryStreet, setSecondaryStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [errors, setErrors] = useState({});

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const validateForm = () => {
    const newErrors = {};

    if (!department || department === "undefined") {
      newErrors.department = "El departamento es requerido";
    }

    if (!city || city === "undefined") {
      newErrors.city = "La ciudad es requerida";
    }

    if (!neighborhood) {
      newErrors.neighborhood = "El barrio es requerido";
    }

    if (!country.trim()) {
      newErrors.country = "El país es requerido";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const saveAddress = () => {
    const address = {
      city: cities.find((cityObj) => cityObj.id == city)?.name,
      department: departments.find((deptObj) => deptObj.id == department)?.name,
      neighborhood: neighborhoods.find(
        (neighObj) => neighObj.id == neighborhood
      )?.name,
      description: description,
      country: country,
      principalStreet: principalStreet,
      secondaryStreet: secondaryStreet,
      houseNumber: houseNumber,
      coordinates: coordinates,
      type_address: "",
    };

    updateDatosUbicacion(address);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isDetail) {
      nextStep();
    }
    if (validateForm()) {
      saveAddress();
      nextStep();
    }
  };

  const allowOnlyNumbers = (value) => {
    if (/^\d*$/.test(value)) {
      setHouseNumber(value);
    }
  };

  const returnValue = (value) => {
    try {
      return JSON.parse(value).name;
    } catch (e) {
      return value;
    }
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => setCities(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status == 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const handleChangeNeighborhood = (event, value) => {
    event.preventDefault();
    setNeighborhood(typeof value === "object" ? value.neighborhood : value);
    setErrors({ ...errors, neighborhood: "" });
  };

  useEffect(() => {
    findDeparments();
  }, []);
  useEffect(() => {
    if (department != undefined) {
      findCities(department);
      if (institution) {
        setCity(
          cities.filter(
            (cityObj) => cityObj.name == institution.address.city
          )[0]?.id
        );
        console.log(city);
      }
    }
  }, [department]);
  useEffect(() => {
    if (city != undefined) {
      findNeighborhoods(city);
      if (institution) {
        setNeighborhood(
          neighborhoods.filter(
            (neighObj) => neighObj.name == institution.address.neighborhood
          )[0]?.id
        );
      }
    }
  }, [city]);
  useEffect(() => {
    if (institution && isDetail) {
      setCoordinates(
        institution?.address?.coordinates?.latitude +
          " " +
          institution?.address?.coordinates?.longitude
      );

      setDescription(institution?.address?.description || "");
      setCountry(institution?.address?.country || "");
      setPrincipalStreet(institution?.address?.principalStreet || "");
      setSecondaryStreet(institution?.address?.secondaryStreet || "");
      setHouseNumber(institution?.address?.houseNumber || "");
      // setNeighborhood(institution.address.neighborhood);
      // setCity(cities.filter((cityObj) => cityObj.name == institution.address.city)[0]?.id);
    }
  }, [institution]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Col>
            <Form.Label className={styles.groupLabel}>Dirección</Form.Label>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Floating>
              <Form.Control
                as="select"
                id="departmentField"
                disabled={isDetail}
                className={`${styles.input} ${
                  errors.department ? "is-invalid" : ""
                }`}
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                  setErrors({ ...errors, department: "" });
                }}
              >
                <option value="">
                  {isDetail ? institution.address.department : "Seleccione..."}
                </option>

                {departments.map((current) => (
                  <option key={current.id} value={current.id}>
                    {current.name}
                  </option>
                ))}
              </Form.Control>
              <label htmlFor="deparmentField" className={styles.label}>
                Departamento
              </label>
              {errors.department && (
                <Form.Control.Feedback type="invalid">
                  {errors.department}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </Col>
          <Col md={4}>
            <Form.Floating>
              <Form.Control
                as="select"
                id="cityField"
                disabled={isDetail}
                className={`${styles.input} ${errors.city ? "is-invalid" : ""}`}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  setErrors({ ...errors, city: "" });
                }}
              >
                <option value="">
                  {isDetail ? institution.address.city : "Seleccione..."}
                </option>
                {cities.map((current) => (
                  <option key={current.id} value={current.id}>
                    {current.name}
                  </option>
                ))}
              </Form.Control>
              <label htmlFor="cityField" className={styles.label}>
                Ciudad
              </label>
              {errors.city && (
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Floating>
                {isDetail ? (
                  <>
                    <Form.Control
                      id="neighborhoods"
                      type="text"
                      name="neighborhoods"
                      value={institution.address.neighborhood}
                      disabled={isDetail}
                      onChange={(e) => setCoordinates(e.target.value)}
                      className={styles.input}
                    />
                    <label htmlFor="neighborhoods" className={styles.label}>
                      Barrio / Localidad
                    </label>
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={neighborhoods}
                      getOptionLabel={(option) =>
                        typeof option == "object" ? option.name : option
                      }
                      value={JSON.stringify(neighborhood)}
                      freeSolo
                      autoSelect
                      onChange={handleChangeNeighborhood}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Barrio / Localidad"}
                          classname={styles.input}
                        />
                      )}
                    />
                  </>
                )}
              </Form.Floating>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4" style={{ display: "flex", paddingTop: ".7rem" }}>
          <Col md={8}>
            <Form.Floating>
              <Form.Control
                id="descriptionTextfield"
                type="text"
                name="description"
                value={description}
                disabled={isDetail}
                className={styles.input}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Ingrese una breve descripción de la dirección"
              />
              <label htmlFor="descriptionTextfield" className={styles.label}>
                Descripción
              </label>
            </Form.Floating>
          </Col>
          <Col md={4}>
            <Form.Floating>
              <Form.Control
                id="countryTextfield"
                type="text"
                name="country"
                value={country}
                disabled={isDetail}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setErrors({ ...errors, country: "" });
                }}
                placeholder="Ingrese el país"
                className={`${styles.input} ${
                  errors.country ? "is-invalid" : ""
                }`}
              />
              <label htmlFor="countryTextfield" className={styles.label}>
                País
              </label>
              {errors.country && (
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mt-4" style={{ display: "flex", paddingTop: ".7rem" }}>
          <Col md={3}>
            <Form.Floating>
              <Form.Control
                id="principalStreetTextField"
                type="text"
                name="principalStreet"
                disabled={isDetail}
                value={principalStreet}
                onChange={(e) => setPrincipalStreet(e.target.value)}
                placeholder="Ingrese la calle principal"
                className={styles.input}
              />
              <label
                htmlFor="principalStreetTextField"
                className={styles.label}
              >
                Calle principal
              </label>
            </Form.Floating>
          </Col>
          <Col md={3}>
            <Form.Floating>
              <Form.Control
                id="secondaryStreetTextfield"
                type="text"
                name="secondaryStreet"
                value={secondaryStreet}
                disabled={isDetail}
                onChange={(e) => setSecondaryStreet(e.target.value)}
                placeholder="Ingrese la calle secundaria"
                className={styles.input}
              />
              <label
                htmlFor="secondaryStreetTextfield"
                className={styles.label}
              >
                Calle secundaria
              </label>
            </Form.Floating>
          </Col>
          <Col md={3}>
            <Form.Floating>
              <Form.Control
                id="houseNumberTextfield"
                type="text"
                name="houseNumber"
                value={houseNumber}
                disabled={isDetail}
                onChange={(e) => allowOnlyNumbers(e.target.value)}
                placeholder="Ingrese el número de casa"
                className={styles.input}
              />
              <label htmlFor="houseNumberTextfield" className={styles.label}>
                Número de casa
              </label>
            </Form.Floating>
          </Col>
          <Col md={3}>
            <Form.Floating>
              <Form.Control
                id="coordinatesTextfield"
                type="text"
                name="coordinates"
                value={coordinates}
                disabled={isDetail}
                onChange={(e) => setCoordinates(e.target.value)}
                placeholder="Ingrese las coordenadas"
                className={styles.input}
              />
              <label htmlFor="coordinatesTextfield" className={styles.label}>
                Coordenadas
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mt-4" style={{ display: "flex", paddingTop: ".7rem" }}>
          <Col md={6} style={{ marginLeft: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={prevStep}>
              ANTERIOR
            </Button>
          </Col>
          <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
            <Button bsPrefix={styles.next} type="submit">
              SIGUIENTE
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DatosUbicacion;
