import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "../DenunciantesModal.module.css";
import { useKeycloak } from "@react-keycloak/web";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import toast from "react-hot-toast";

const JobsStep = ({
  handleSave,
  prevStep,
  updateJobs,
  jobs,
  setJobs,
  type,
  setMandatorio,
  mandatorio,
  attachments,
  setAttachments,
  isDenunciado,
  isDetail,
  person,
}) => {
  const [validated, setValidated] = useState(false);
  const [institutionId, setInstitutionId] = useState(undefined);

  const [institutionOptions, setInstitutionOptions] = useState([]);

  const [rol, setRol] = useState("");
  const [rolDescription, setRolDescription] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [institutionName, setInstitutionName] = useState(undefined);

  const [showJob, setShowJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const [key, setKey] = useState(0);

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findInstitution = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/institutions?page=1&pageSize=100`,
        prepareHeaders()
      )
      .then((res) => {
        setInstitutionOptions(res.status == 204 ? [] : res.data.content);
      })
      .catch((err) => {
        console.log(err);
        setInstitutionOptions([]);
      });
  };

  useEffect(() => {
    findInstitution();
  }, []);

  const handleChange = (event, value, reason, details) => {
    event.preventDefault();
    setInstitutionName(value.name);
    setInstitutionId(value.institution_id);
  };

  const removeJob = (e, job) => {
    e.preventDefault();
    const updatedJobs = jobs.filter((current) => current !== job);

    setJobs(updatedJobs);

    updateJobs(updatedJobs);
  };

  const array = () => {
    if (!validateFields()) {
      return;
    }
    const newJob = {
      institution_id: institutionId,
      rol: rol,
      rol_description: rolDescription,
      from_date: fromDate,
      to_date: toDate,
    };
    const newJobs = [...jobs];
    newJobs.push(newJob);
    setJobs(newJobs);
    updateJobs(newJobs);
    cleanAll();
  };

  const cleanAll = () => {
    setInstitutionId(null);
    setRol("");
    setRolDescription("");
    setFromDate("");
    setToDate("");
    setKey(key + 1);
  };

  const validateFields = () => {
    if (!institutionId || !rol) {
      toast.error("Debe seleccionar una institución y cargar un rol.");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave();
  };

  return (
    <>
      <Form noValidate validated={validated}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <div className={styles.container}>
            <Row className="mb-4">
              <Form.Label className={styles.groupLabel}>
                Datos Laborales
              </Form.Label>
              <Col md={6}>
                <Autocomplete
                  //multiple
                  key={key}
                  id="tags-filled"
                  disabled={isDetail}
                  options={institutionOptions.map((option) => option)}
                  freeSolo
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props} key={option.institution_id}>
                      {" "}
                      {option.name}
                    </li>
                  )}
                  onChange={handleChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Institución/ Lugar de trabajo"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    disabled={isDetail}
                    id="rol"
                    type="text"
                    value={rol || ""}
                    onChange={(e) => {
                      setRol(e.target.value);
                    }}
                    placeholder="Ingrese el rol"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="rol">
                    Rol
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    disabled={isDetail}
                    id="rol_description"
                    value={rolDescription || ""}
                    onChange={(e) => {
                      setRolDescription(e.target.value);
                    }}
                    className={styles.input}
                    type="text"
                    placeholder="Ingrese la descripción del rol"
                  />
                  <label className={styles.label} htmlFor="rol_description">
                    Descripción
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="from_date"
                    disabled={isDetail}
                    required={false}
                    value={fromDate || ""}
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    className={styles.input}
                    placeholder="Ingrese la fecha en que empezó"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <label htmlFor="from_date" className={styles.label}>
                    Fecha en la cuál ingresó
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="to_date"
                    required={false}
                    disabled={isDetail}
                    value={toDate || ""}
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                    className={styles.input}
                    placeholder="Ingrese la fecha en que terminó"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <label htmlFor="to_date" className={styles.label}>
                    Fecha en la cuál terminó
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            {jobs && jobs.length > 0 ? (
              <Table className={styles.table} style={{ marginTop: "1rem" }}>
                <thead>
                  <th>Institución Laboral</th>
                  <th>Rol</th>
                  <th>Descripción</th>
                  <th>Fecha de ingreso</th>
                  <th>Fecha de salida</th>
                  {!isDetail && <th>Acción</th>}
                </thead>
                <tbody>
                  {jobs.map((job) => (
                    <tr>
                      <td>
                        {institutionOptions.find(
                          (institution) =>
                            institution.institution_id === job.institution_id
                        )?.name || "No especificada"}
                      </td>
                      <td>{job.rol}</td>
                      <td>{job.rol_description}</td>
                      <td>{job.from_date}</td>
                      <td>{job.to_date}</td>
                      {!isDetail && (
                        <td>
                          <AiOutlineFileSearch
                            onClick={(e) => {
                              e.preventDefault();
                              if (isDetail) {
                                return;
                              }
                              const institution = institutionOptions.find(
                                (inst) =>
                                  inst.institution_id === job.institution_id
                              );
                              setSelectedJob({ ...job, institution });
                              setShowJob(true);
                            }}
                            title="Ver"
                            className={styles.icon}
                          />
                          &nbsp;
                          <FiTrash
                            onClick={(e) => {
                              if (isDetail) {
                                return;
                              }
                              removeJob(e, job);
                            }}
                            title="Descartar"
                            className={styles.icon}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}

            {isDetail ? (
              <></>
            ) : (
              <Button
                bsPrefix={styles.next}
                onClick={() => {
                  array();
                }}
                className="mt-3"
              >
                GUARDAR TRABAJO
              </Button>
            )}
            {isDenunciado ? (
              <>
                <Row className="mb-4" style={{ paddingTop: "2vh" }}>
                  <Form.Group as={Col} md={2} controlId="mandatarioValidation">
                    <Form.Check
                      label="Por mandatario"
                      type="switch"
                      disabled={type === "IP"}
                      name="porMandatorio"
                      id="porMandatorio"
                      style={{
                        accentColor: "#AD0039",
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingBottom: "0px",
                        paddingRight: "0px",
                      }}
                      defaultChecked={mandatorio}
                      onChange={(e) => setMandatorio(e.target.checked)}
                      className={styles.label}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  {mandatorio ? (
                    <Form.Group as={Col}>
                      <Form.Label className={styles.label}>
                        Poder judicial
                      </Form.Label>
                      <Row>
                        <Col md={10}>
                          <Form.Control
                            required
                            type="file"
                            multiple={true}
                            placeholder="Seleccione los informes"
                            className={styles.fileInput}
                            onChange={(e) =>
                              setAttachments(Array.from(e.target.files))
                            }
                          />
                        </Col>
                      </Row>
                      {attachments && attachments.length > 0 ? (
                        <Row className="mt-2">
                          {attachments.map((file) => (
                            <Col md="auto" style={{ paddingRight: "0px" }}>
                              <Badge bg="secondary">{file.name}</Badge>
                            </Col>
                          ))}
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row
              className="mt-4"
              style={{ display: "flex", paddingTop: ".7rem" }}
            >
              <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
                <Button bsPrefix={styles.next} onClick={prevStep}>
                  ANTERIOR
                </Button>
              </Col>
            </Row>

            <Row>
              <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                {isDetail ? (
                  <></>
                ) : (
                  <Button bsPrefix={styles.next} onClick={handleSubmit}>
                    AGREGAR
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default JobsStep;
