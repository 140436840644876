import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import styles from "./ContraloriaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";

const Person = ({
  show,
  handleClose,
  handleSave,
  funcionarios,
  setFuncionarios,
}) => {
  const { keycloak } = useKeycloak();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactType, setContactType] = useState("cel");
  const [contactValue, setContactValue] = useState("");
  const [title, setTitle] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      const newFuncionario = {
        firstName,
        lastName,
        rol: title,
        document_number: documentNumber,
  contact: [{ type: contactType, contact_value: contactValue }],
      };
      console.log(newFuncionario);
      setFuncionarios([...funcionarios, newFuncionario]);
      handleClose(); 
      clearFields();
    }
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setContactType("cel");
    setContactValue("");
    setTitle("");
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Funcionario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit}>
          <h4 className={styles.groupLabel}>Datos personales</h4>
          <Row className="mb-4">
            <Col controlId="nameValidation">
              <Form.Floating>
                <Form.Control
                  required
                  id="floatingName"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Ingrese el nombre"
                  className={styles.input}
                />
                <label htmlFor="floatingName" className={styles.label}>
                  Nombres
                </label>
              </Form.Floating>
            </Col>
            <Form.Group as={Col} controlId="lastNameValidation">
              <Form.Floating>
                <Form.Control
                  required
                  id="floatingLastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Ingrese el apellido"
                  className={styles.input}
                  type="text"
                />
                <label htmlFor="floatingLastName" className={styles.label}>
                  Apellidos
                </label>
              </Form.Floating>
            </Form.Group>
          </Row>
          <Row className="mb-4">
            <Col md={4} controlId="documentNumberValidation">
              <Form.Floating>
                <Form.Control
                  required
                  id="floatingDocumentNumber"
                  type="text"
                  value={documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                  placeholder="Ingrese el número de documento"
                  className={styles.input}
                />
                <label
                  htmlFor="floatingDocumentNumber"
                  className={styles.label}
                >
                  Cédula de Identidad
                </label>
              </Form.Floating>
            </Col>
          </Row>

          <h4 className={styles.groupLabel}>Contacto</h4>
          <Row className="mb-4">
            <Form.Group as={Col} controlId="contactTypeValidation">
              <Form.Floating>
                <Form.Control
                  id="floatingContactType"
                  className={styles.input}
                  as="select"
                  value={contactType}
                  onChange={(e) => setContactType(e.target.value)}
                >
                  <option value="cel">Celular</option>
                  <option value="email">Email</option>
                  <option value="tel">Teléfono</option>
                </Form.Control>
                <label htmlFor="floatingContactType" className={styles.label}>
                  Tipo de Contacto
                </label>
              </Form.Floating>
            </Form.Group>
            <Form.Group as={Col} controlId="contactValueValidation">
              <Form.Floating>
                <Form.Control
                  id="floatingContactValue"
                  required
                  type="text"
                  value={contactValue}
                  onChange={(e) => setContactValue(e.target.value)}
                  placeholder="Ingrese el contacto"
                  className={styles.input}
                />
                <label htmlFor="floatingContactValue" className={styles.label}>
                  Contacto
                </label>
              </Form.Floating>
            </Form.Group>
          </Row>

          <h4 className={styles.groupLabel}>Cargo</h4>
          <Row className="mb-4">
            <Form.Group as={Col} controlId="cargoValidation">
              <Form.Floating>
                <Form.Control
                  id="floatingCargo"
                  required
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Ingrese el cargo"
                  className={styles.input}
                />
                <label htmlFor="floatingCargo" className={styles.label}>
                  Cargo
                </label>
              </Form.Floating>
            </Form.Group>
          </Row>

          <Row>
            <Col className="text-start">
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
            </Col>
            <Col className="text-end">
              <Button type="submit" variant="primary">
                Agregar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Person;
